import { useSelector } from 'react-redux';
import { collection, query, orderBy } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import useFirestoreQueryBatched from './useFirestoreQueryBatched/useFirestoreQueryBatched';

const useGetEventTypes = (shouldFetch = true, divId) => {
  const orgId = useSelector(state => state.organizations.organization.id);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);

  let queriesList = [];

  if (shouldFetch) {
    if (divId) {
      queriesList = [
        query(
          collection(db, 'organizations', orgId, 'divisions', divId, 'event_types'),
          orderBy('createdAt'),
        ),
      ];
    } else {
      queriesList = selectedDivsIds.map(id =>
        query(
          collection(db, 'organizations', orgId, 'divisions', id, 'event_types'),
          orderBy('createdAt'),
        ),
      );
    }
  }

  const { data, loading } = useFirestoreQueryBatched(queriesList, [selectedDivsIds, divId]);

  return {
    data,
    loading,
  };
};

export default useGetEventTypes;
