import { useState, useEffect } from 'react';

const useStopWatch = limitTime => {
  const [time, setTime] = useState(null);
  const [isPaused, setIsPaused] = useState(false);

  const initTime = () => {
    setTime(0);
    setIsPaused(false);
  };
  useEffect(() => {
    let intervalo;
    if (time !== null && !isPaused) {
      intervalo = setInterval(() => {
        setTime(prevtime => (prevtime === limitTime ? 0 : prevtime + 1));
      }, 1000);
    }

    return () => clearInterval(intervalo);
  }, [limitTime, time, isPaused]);

  const resetTime = () => {
    setTime(0);
    setIsPaused(false);
  };

  const pauseTime = () => {
    setIsPaused(true);
  };

  const resumeTime = () => {
    setIsPaused(false);
  };

  return { time, resetTime, initTime, pauseTime, resumeTime };
};

export default useStopWatch;
