import React from 'react';
import styles from './styles.module.less';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ORDER_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
};

const SorterColumn = ({ column, onChange, defaultValues }) => {
  const defaultOrder = defaultValues.reduce((acc, el) => {
    const key = el.name;
    const value = {
      order: el.order,
    };
    acc[key] = value;
    return acc;
  }, {});

  const [activeOrder, setActiveOrder] = React.useState(defaultOrder);

  const onSorter = ({ order, column }) => {
    const currentOrder = activeOrder?.[column]?.order;
    const sortFields = {};

    if (currentOrder === order) {
      sortFields[column] = {
        order: null,
      };
    } else {
      sortFields[column] = {
        order,
      };
    }

    setActiveOrder(sortFields);
    onChange(sortFields);
  };

  return (
    <span className="sort-icons gx-pointer">
      <i
        className={classNames(
          'material-icons',
          activeOrder?.[column]?.order === ORDER_TYPES.ASC && styles.active,
        )}
        onClick={() => onSorter({ order: ORDER_TYPES.ASC, column: column })}
      >
        arrow_upward
      </i>
      <i
        className={classNames(
          'material-icons',
          activeOrder?.[column]?.order === ORDER_TYPES.DESC && styles.active,
        )}
        onClick={() => onSorter({ order: ORDER_TYPES.DESC, column: column })}
      >
        arrow_downward
      </i>
    </span>
  );
};

SorterColumn.propTypes = {
  column: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      order: PropTypes.oneOf([ORDER_TYPES.ASC, ORDER_TYPES.DESC, null]),
    }),
  ),
};
export default SorterColumn;
