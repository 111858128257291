import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

const Toggle = ({ value = false, className = '' }) => (
  <div className={className}>
    <Switch checked={value} disabled />
  </div>
);

Toggle.propTypes = {
  value: PropTypes.bool,
  className: PropTypes.string,
};

export default React.memo(Toggle);
