import React from 'react';

import IntlMessages from 'util/IntlMessages';
import { InfoCardList } from 'components/InfoCard';
import { convertToDate } from 'packages/utils/dates';
import { generateKey } from 'packages/utils';

export default function Descriptions({ data = [] }) {
  return data.map(({ description = '', date } = {}) => (
    <InfoCardList
      key={generateKey(description)}
      title={description || <IntlMessages id="monitoring.location.alerts.details.no.description" />}
      subTitle={
        <span>
          {convertToDate(date?.seconds, date?.nanoseconds).toLocaleDateString(undefined, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })}
        </span>
      }
    />
  ));
}
