import classNames from 'classnames';
import React from 'react';
import { Card, Button } from 'antd';
import { generateKey, useIsMobile } from 'packages/utils';
import styles from './styles.module.less';
import ComponentBlocker from 'components/ComponentBlocker';
import { EditTwoTone, DeleteTwoTone, CopyTwoTone } from '@ant-design/icons';
import { buttonCommonClass } from './constants';
import CircleButton from 'components/Buttons/CircleButton';
import { infoCardProptypes } from 'packages/utils/proptypes/components/infoCard';

/**
 * InfoCard wrapper component.
 */

const InfoCard = React.memo(
  ({
    children,
    className,
    title,
    subTitle,
    actionButtons,
    type = 'primary',
    buttonItems,
    extraRightContent = null,
  }) => {
    const { isMobile } = useIsMobile();

    const TOP_ICONS_BUTTONS = {
      edit: props => <EditTwoTone {...props} />,
      delete: props => <DeleteTwoTone {...props} twoToneColor="#E13E3B" />,
      clone: props => <CopyTwoTone {...props} />,
    };
    const AUX_CLASS_BY_BUTTON = {
      edit: styles.editIconButton,
      delete: styles.deleteIconButton,
      clone: styles.cloneIconButton,
    };

    // NOTE: only will render when is mobile.
    const mobileButtons = () => {
      if (!isMobile) return [];
      return actionButtons?.map(bt => {
        if (bt?.hidden) return null;

        return (
          <React.Fragment>
            {bt?.allowedRole && bt.allowedRole.length > 0 ? (
              <ComponentBlocker divId={bt?.divId} allowedRoles={bt.allowedRole}>
                {TOP_ICONS_BUTTONS[bt.iconButton]({
                  onClick: bt?.action,
                })}
              </ComponentBlocker>
            ) : (
              TOP_ICONS_BUTTONS[bt.iconButton]({
                onClick: bt?.action,
              })
            )}
          </React.Fragment>
        );
      });
    };

    const variants = {
      primary: styles.primary,
      light: styles.light,
    };

    const getCircleButtons = () =>
      buttonItems?.map((bt, idx) => {
        if (bt?.hidden) return null;

        return (
          <React.Fragment key={generateKey(idx)}>
            {bt?.allowedRole && bt?.allowedRole.length > 0 ? (
              <ComponentBlocker allowedRoles={bt.allowedRole} divId={bt?.divId}>
                <CircleButton
                  iconName={bt?.iconName}
                  onClick={bt.action}
                  disabled={bt?.disabled || false}
                  hidden={bt?.hidden || false}
                  label={bt?.label}
                  tooltipText={bt?.tooltipText}
                  type={bt?.type}
                  iconClassName={bt?.iconClassName}
                  iconNameText={bt?.iconNameText}
                />
              </ComponentBlocker>
            ) : (
              <CircleButton
                iconName={bt?.iconName}
                onClick={bt.action}
                disabled={bt?.disabled || false}
                hidden={bt?.hidden || false}
                label={bt?.label}
                tooltipText={bt?.tooltipText}
                type={bt?.type}
                iconClassName={bt?.iconClassName}
                iconNameText={bt?.iconNameText}
              />
            )}
          </React.Fragment>
        );
      });

    return (
      <div className={classNames(className, 'gx-w-100', styles.cardContainer)}>
        <Card
          title={
            title || subTitle ? (
              <div className="gx-w-100">
                {title && <div>{title}</div>}
                {subTitle && <div>{subTitle}</div>}
              </div>
            ) : null
          }
          extra={
            <div className="gx-flex-row gx-guarnic-gap-1 gx-align-items-center">
              {extraRightContent && <div>{extraRightContent}</div>}

              {buttonItems?.length > 0 && getCircleButtons()}
              {actionButtons?.length > 0 && (
                <div>
                  {!isMobile &&
                    actionButtons?.length > 0 &&
                    actionButtons?.map((bt, idx) => {
                      if (bt?.hidden) return null;
                      return (
                        <React.Fragment key={generateKey(idx)}>
                          {bt?.allowedRole && bt?.allowedRole.length > 0 ? (
                            <ComponentBlocker divId={bt?.divId} allowedRoles={bt.allowedRole}>
                              <Button
                                {...buttonCommonClass({ props: bt })}
                                icon={TOP_ICONS_BUTTONS[bt?.iconButton]()}
                                className={bt?.iconButton && AUX_CLASS_BY_BUTTON[bt?.iconButton]}
                              >
                                {bt?.label}
                              </Button>
                            </ComponentBlocker>
                          ) : (
                            <Button
                              {...buttonCommonClass({ props: bt })}
                              icon={TOP_ICONS_BUTTONS[bt?.iconButton]()}
                              className={bt?.iconButton && AUX_CLASS_BY_BUTTON[bt?.iconButton]}
                            >
                              {bt?.label}
                            </Button>
                          )}
                        </React.Fragment>
                      );
                    })}
                </div>
              )}
            </div>
          }
          className={classNames(styles.card, !children && styles.emptyChildren, variants[type])}
          headStyle={{ padding: '0px', borderBottom: !children && 'none' }}
          bodyStyle={!children ? { padding: '0px' } : { padding: '10px 0 0 0' }}
          actions={mobileButtons()}
        >
          {children}
        </Card>
      </div>
    );
  },
);

// SubCompoents
InfoCard.Title = ({ value }) => (
  <div className="gx-guarnic-card-title gx-align-content-center gx-guarnic-break-word">{value}</div>
);
InfoCard.SubTitle = ({ value }) => <div className="gx-guarnic-headline-1">{value}</div>;

InfoCard.propTypes = infoCardProptypes;

export { InfoCard };
