import React, { useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import styles from './styles.module.less';
import 'leaflet/dist/leaflet.css';
import BaseLayers from './BaseLayers';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spin } from 'antd';

const Map = ({
  children,
  zoom = 13,
  center = [51.505, -0.09],
  className = '',
  loading = false,
  mapRef = null,
  ...props
}) => {
  const [activeLayer, setActiveLayer] = useState('OpenStreetMap');

  return (
    <MapContainer
      {...props}
      zoom={zoom}
      center={center}
      className={classNames(styles.mapContainer, className)}
      ref={mapRef}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <BaseLayers onActiveLayer={setActiveLayer} layer={activeLayer} />

      {loading && <Spin />}
      {children}
    </MapContainer>
  );
};

Map.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  zoom: PropTypes.number,
  center: PropTypes.array,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export default Map;
