import React, { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { Badge, Pagination } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import { database } from 'firebase/firebase';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import MemberAvatar from 'components/MemberList/MemberAvatar';
import { equalTo, orderByChild, ref, query as queryRTD } from 'firebase/database';
import useRemoteConfig from 'packages/utils/hooks/useRemoteConfig';
import { useIsMobile } from 'packages/utils';
import { AimOutlined, CalendarOutlined, UnorderedListOutlined } from '@ant-design/icons';
import TimelineByUser from 'packages/dashboard/components/ActiveUserDashboard/TimelineByUser';
import SchedulesActive from 'packages/dashboard/components/ActiveUserDashboard/SchedulesActive';
import UserLocationTracker from 'packages/dashboard/components/ActiveUserDashboard/UserLocationTracker';
import useGetDataFromDatabase from 'packages/utils/hooks/useGetDataFromDatabase/useGetDataFromDatabase';

import useGetUserMetricsPaginated from 'packages/utils/hooks/collections/useGetUserMetricsPaginated';
import BatteryStatus from 'components/Widget/BatteryStatus';
import { ResponsiveTable } from 'components/Table';
import styles from './styles.module.less';
import { factoryActiveUsers, INIT_FILTERS } from './constants';
import ActiveUserFilter from '../../components/ActiveUserDashboard/ActiveUserFilter';

const ActiveUserDashboard = () => {
  const mapRef = React.useRef(null);
  const { isMobile } = useIsMobile();
  const { value: userAlloweds, isLoaded } = useRemoteConfig('active_user_view');

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(INIT_FILTERS);
  const [scheduleByUserOpen, setScheduleByUserOpen] = useState(false);
  const [timelineByUserVisible, setTimelineByUserVisible] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [userFocused, setUserFocused] = useState(null);

  // Selectors
  const orgId = useSelector(state => state.organizations.organization.id);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids || []);

  // Handlers
  const handleSchedulesByUser = user => {
    setScheduleByUserOpen(true);
    setUserSelected(user);
  };

  const handleTimelineByUser = user => {
    setTimelineByUserVisible(true);
    setUserSelected(user);
  };

  const handleFindUser = user => {
    if (mapRef.current) mapRef.current.setZoom(13);
    // NOTE: we are going to evaluate if are equals we are going to unchecked the marker on the map
    setUserFocused(prev => (prev?.id === user?.id ? null : user));
  };

  const start = (currentPage - 1) * filters.userPerDisplay;
  const end = start + filters.userPerDisplay;

  const {
    data: users,
    loading,
    next,
  } = useGetUserMetricsPaginated({ orgId, pageSize: filters.userPerDisplay, range: [start, end] });
  const queryRefs = [queryRTD(ref(database, 'users'), orderByChild(orgId), equalTo(orgId))];

  const {
    data: userLocations = [],
    loading: locationLoading,
    error,
  } = useGetDataFromDatabase(queryRefs, [users]);

  const mainLoading = loading || locationLoading;

  const USER_COLUMNS = [
    {
      title: <IntlMessages id="activeUserDashboard.user.column" />,
      dataIndex: 'displayName',
      key: 'displayName',
      align: 'left',
      render: value => (
        <div className="gx-flex-row">
          {!isMobile && <MemberAvatar user={value} />}
          <div className={classNames('gx-align-self-center gx-pl-2', styles.userColumn)}>
            {value?.displayName || '-'}
          </div>
        </div>
      ),
    },
    {
      title: <IntlMessages id="activeUserDashboard.connected.column" />,
      dataIndex: 'connected',
      key: 'connected',
      align: 'left',
      render: value => (
        <div className="gx-flex-row gx-align-items-center gx-guarnic-gap-1">
          <span>
            <IntlMessages id={value?.connected ? 'common.connected' : 'common.no.connected'} />
          </span>
          <Badge className="gx-m-0" color={value?.connected ? '#3ea42d' : '#c82727'} />
        </div>
      ),
    },
    {
      title: <IntlMessages id="activeUserDashboard.battery.column" />,
      dataIndex: 'battery',
      key: 'battery',
      align: 'left',
      render: value => <BatteryStatus.Single batery={value?.battery} />,
    },
    {
      title: <IntlMessages id="activeUserDashboard.scheduleActives.column" />,
      dataIndex: 'scheduleDetails',
      key: 'scheduleDetails',
      contentAlign: 'center',
      render: value => (
        <CalendarOutlined
          className={styles.auxColumns}
          onClick={() => handleSchedulesByUser(value)}
        />
      ),
      width: '15%',
    },
    {
      title: <IntlMessages id="activeUserDashboard.timelineDetails.column" />,
      dataIndex: 'timelineDetails',
      key: 'timelineDetails',
      contentAlign: 'center',
      render: value => (
        <UnorderedListOutlined
          className={styles.auxColumns}
          onClick={() => handleTimelineByUser(value)}
        />
      ),
      width: '15%',
    },
    {
      title: <IntlMessages id="activeUserDashboard.findUser.column" />,
      dataIndex: 'findUser',
      key: 'findUser',
      contentAlign: 'center',
      render: value => {
        const output = value?.positions ? (
          <div className="gx-flex-row gx-justify-content-xl-center gx-justify-content-start">
            <div className={value.isSelected ? styles.userFocused : null}>
              <AimOutlined
                className={classNames(styles.auxColumns)}
                onClick={() => handleFindUser(value)}
              />
            </div>
          </div>
        ) : (
          <span className="material-symbols-outlined">signal_disconnected</span>
        );

        return output;
      },
      width: '15%',
    },
  ];

  const totalPagination = users.length + filters.userPerDisplay;

  const userList = useMemo(
    () =>
      factoryActiveUsers({
        users,
        userLocations,
        userFocused,
      }),
    [users, userLocations, userFocused],
  );

  // NOTE: Control Pagination
  const handlePageChange = page => {
    setCurrentPage(page);

    if (!loading && page > currentPage) {
      next();
    }
  };

  const recordsToShow = useMemo(() => {
    const regexByText = new RegExp(filters.text, 'i');

    return userList
      .slice(start, end)
      .filter(
        usr =>
          Object.keys(usr.divisions).some(divId => selectedDivsIds.includes(divId)) &&
          (usr.connected === filters.status || filters.status === null) &&
          regexByText.test(usr.displayName),
      )
      .sort(a => (a.positions ? -1 : 1)); // NOTE: sorting by position since we want to show first the users with position
  }, [end, filters.status, filters.text, selectedDivsIds, start, userList]);

  // Manual Filter
  const handleFilter = React.useCallback((field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value,
    }));
  }, []);

  useEffect(() => {
    if (!userAlloweds && isLoaded) {
      history.push('/');
    }
  }, [history, isLoaded, userAlloweds]);

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          title={<Title.Header value={<IntlMessages id="dashboards.active_users" />} />}
          content={<ActiveUserFilter onChangeFilter={handleFilter} />}
          showHide
        />
      </BoxContainer>
      <BoxContainer content error={error}>
        <UserLocationTracker mapRef={mapRef} users={userList} currentUser={userFocused} />
        <ResponsiveTable
          loading={mainLoading}
          type="primary"
          columns={USER_COLUMNS}
          dataSource={recordsToShow}
        />
        <div className="gx-flex-row gx-justify-content-center gx-mt-3">
          <Pagination
            current={currentPage}
            onChange={handlePageChange}
            pageSize={filters.userPerDisplay}
            total={totalPagination}
            size="small"
          />
        </div>
      </BoxContainer>
      {scheduleByUserOpen && !mainLoading && (
        <SchedulesActive
          user={userSelected}
          isVisible={scheduleByUserOpen}
          onCancel={setScheduleByUserOpen}
        />
      )}
      {timelineByUserVisible && !mainLoading && (
        <TimelineByUser
          user={userSelected}
          isVisible={timelineByUserVisible}
          onCancel={setTimelineByUserVisible}
        />
      )}
    </BoxContainer>
  );
};

export default ActiveUserDashboard;
