import { useCallback, useEffect, useRef, useState } from 'react';

import { collection, query } from 'firebase/firestore';

import { db } from 'firebase/firebase';
import { STATUS_OPTIONS } from 'components/Users/constants';
import subscribeToUserMetrics from 'util/firebase-operations/users/subscribeToUserMetrics';
import useFirestoreRealtimeBatched from '../useFirestoreRealtimeBatched/useFirestoreRealtimeBatched';

const INITIAL_METRICS = {
  activeTasks: 0,
  activeSchedules: 0,
  lastLocation: {},
};

const useGetSelectUsers = ({ orgId, divId }) => {
  const [data, setData] = useState([]);
  const [metrics, setMetrics] = useState(INITIAL_METRICS);
  const metricsRef = useRef({});

  const userQuery = [query(collection(db, 'organizations', orgId, 'divisions', divId, 'users'))];
  const groupQuery = [query(collection(db, 'organizations', orgId, 'divisions', divId, 'groups'))];

  const metricsIsLoading = Object.keys(metricsRef.current).length !== data.length;

  const {
    data: usersData,
    loading: userLoading,
    error: userError,
  } = useFirestoreRealtimeBatched(userQuery, [orgId, divId]);

  const {
    data: groupsData,
    loading: groupLoading,
    error: groupError,
  } = useFirestoreRealtimeBatched(groupQuery, [orgId, divId]);

  const buildData = useCallback(() => {
    const usersByGroup = groupsData.reduce((acc, group) => [...acc, ...group.users], []);
    let groupsByUser = [];

    const output = usersData.reduce((acc, user) => {
      const isAlready = acc.some(usr => usr.id === user.id);
      // check if user has first and last name and already added
      if (isAlready || (!user.firstName && !user.lastName)) return acc;

      const isGroup = usersByGroup.some(usr => usr.id === user.id);

      // check if user is in a group
      if (isGroup) {
        groupsByUser = groupsData.filter(group => group.users.some(usr => usr.id === user.id));
      }

      const displayLabel = `${user?.firstName} ${user?.lastName}`;
      const groupNames = groupsByUser.map(group => group.name);
      let groupDisplayName;
      if (groupsByUser.length === 0) {
        groupDisplayName = '-';
      } else if (groupsByUser.length > 1) {
        groupDisplayName = `+${groupsByUser.length}`;
      } else {
        groupDisplayName = groupsByUser[0].name;
      }

      const metricsByUser = metrics[user.id] || INITIAL_METRICS;

      let getStatus = STATUS_OPTIONS.UNKNOWN;
      if (Object.prototype.hasOwnProperty.call(metricsByUser, 'signedIn')) {
        if (metricsByUser.signedIn) {
          getStatus = STATUS_OPTIONS.AVAILABLE;
        } else {
          getStatus = STATUS_OPTIONS.UNAVAILABLE;
        }
      } else {
        getStatus = STATUS_OPTIONS.UNKNOWN;
      }

      const userParsed = {
        id: user.id,
        displayName: displayLabel,
        totalActiveSchedules: metricsByUser.activeSchedules || 0,
        totalActiveTasks: metricsByUser?.activeTasks || 0,
        status: getStatus,
        isGroup,
        group: {
          name: groupDisplayName,
          groupsByUser,
          groupNames,
        },
        user,
        lastLocation: metricsByUser?.lastLocation?.name || '-',
      };

      return [...acc, userParsed];
    }, []);

    if (JSON.stringify(output) !== JSON.stringify(data)) {
      setData(output);
    }
  }, [groupsData, usersData, data, metrics]);

  const hasError = userError || groupError || metricsIsLoading;
  const loading = userLoading || groupLoading || metricsIsLoading;

  useEffect(() => {
    buildData();
  }, [buildData, metrics]);

  useEffect(() => {
    if (!data) return;

    const unsubscribes = data.map(user => {
      if (user.id) {
        return subscribeToUserMetrics(orgId, user.id, metricsData => {
          metricsRef.current = { ...metricsRef.current, [user.id]: metricsData };
          setMetrics(prevState => ({
            ...prevState,
            [user.id]: metricsData,
          }));
        });
      }
      return null;
    });

    // eslint-disable-next-line consistent-return
    return () => {
      unsubscribes.forEach(unsub => unsub && unsub());
    };
  }, [data, orgId]);

  return {
    data: {
      usersDetails: data,
      users: usersData,
      groups: groupsData,
    },
    loading,
    hasError,
  };
};

export default useGetSelectUsers;
