import PropTypes from 'prop-types';
import auditPropTypes from '../../auditPropTypes';

const layoutPropType = PropTypes.shape({
  h: PropTypes.number,
  w: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
});

const columnsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    componentId: PropTypes.string,
    index: PropTypes.number,
    isActive: PropTypes.bool,
    order: PropTypes.string,
  }),
);
const queryParamsPropType = PropTypes.shape({
  columns: columnsPropTypes,
  formId: PropTypes.string.isRequired,
  interval: PropTypes.oneOf(['day', 'month', 'quarter', 'year', 'week']),
  range: PropTypes.shape({
    dateRange: PropTypes.array,
    dateType: PropTypes.oneOf(['day', 'month', 'quarter', 'year']),
    isRelative: PropTypes.bool.isRequired,
    relativeAmount: PropTypes.number.isRequired,
  }),
  triggerIds: PropTypes.array,
});

export const tablePropType = PropTypes.shape({
  ...auditPropTypes,
  layout: layoutPropType.isRequired,
  queryParams: queryParamsPropType,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  uid: PropTypes.string,
});
