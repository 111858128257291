import React, { useMemo } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Modal, notification, Space } from 'antd';

import safeExecute from 'util/safeExecute';
import IntlMessages from 'util/IntlMessages';
import BoxContainer from 'components/BoxContainer';
import { ResponsiveTable } from 'components/Table';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useGetAllLocations from 'packages/utils/hooks/collections/useGetAllLocations';
import useGetLocationAlerts from 'packages/utils/hooks/collections/useGetLocationAlerts';
import { updateLocationAlert } from 'util/firebase-operations/monitoring/locationAlerts/update';
import { PATHS } from 'packages/monitoring/constants';
import useFullUrl from 'packages/utils/hooks/useFullUrl';
import LocationAlertFilters from 'packages/monitoring/components/LocationAlertFilters';
import { useOrganizationsAllowedRC } from 'packages/utils/hooks/remoteConfigs/useOrganizationsAllowedRC';

function LocationAlerts() {
  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = React.useState({
    name: '',
  });

  useOrganizationsAllowedRC({ remoteConfigName: 'location_alerts_view' });

  const orgId = useSelector(({ organizations }) => organizations.organization.id);
  const intl = useIntl();
  const { goToPath } = useFullUrl();

  const { data: locations, loading: locationsLoading } = useGetAllLocations({
    organizationId: orgId,
  });
  const { data: alerts, loading: locationAlertsLoading, error } = useGetLocationAlerts({ orgId });

  const mainLoading = locationsLoading || locationAlertsLoading || loading;

  const records = useMemo(() => {
    if (!locations || !alerts) return [];

    return alerts
      .map(alert => {
        const location = locations.find(loc => loc.id === alert.id);
        return {
          ...alert,
          location,
        };
      })
      .filter(alert => {
        if (
          filters.name &&
          !alert.location.name.toLowerCase().includes(filters.name.toLowerCase())
        ) {
          return false;
        }

        return true;
      });
  }, [locations, alerts, filters]);

  const onResetLocationAlert = ({ id }) => {
    safeExecute(async () => {
      setLoading(true);
      await updateLocationAlert({
        data: { quantity: 0, descriptions: [] },
        locationAlertId: id,
        orgId,
      })
        .then(() => {
          notification.success({
            message: intl.formatMessage({ id: 'general.save.successful.message' }),
          });
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const COLUMNS = [
    {
      title: <IntlMessages id="monitoring.location.alerts.table.location" />,
      dataIndex: 'location.name',
      key: 'location',
      render: data => data?.location?.name,
    },
    {
      title: <IntlMessages id="monitoring.location.alerts.table.createdAt" />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: data => new Date(data.createdAt?.seconds * 1000).toLocaleString(),
    },
    {
      title: <IntlMessages id="monitoring.location.alerts.table.quantity" />,
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: <IntlMessages id="general.actions" />,
      dataIndex: 'action',
      key: 'action',
      render: data => (
        <Space>
          <Button
            className="gx-m-0"
            type="link"
            disabled={data.quantity === 0}
            onClick={() =>
              Modal.confirm({
                title: intl.formatMessage({ id: 'general.confirmMessage' }),
                icon: <ExclamationCircleOutlined />,
                okText: intl.formatMessage({ id: 'general.yes' }),
                cancelText: intl.formatMessage({ id: 'general.no' }),
                onOk: () => onResetLocationAlert({ id: data.id }),
              })
            }
          >
            <IntlMessages id="monitoring.location.alerts.table.reset" />
          </Button>
          <Button
            className="gx-m-0"
            disabled={data.quantity === 0}
            type="link"
            onClick={() => goToPath(PATHS.LOCATION_ALERT_DETAILS.replace(':id', data.id))}
          >
            <IntlMessages id="monitoring.location.alerts.table.details" />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          title={<Title.Header value={<IntlMessages id="monitoring.location.alerts.title" />} />}
          content={<LocationAlertFilters onFilters={setFilters} />}
        />
      </BoxContainer>
      <BoxContainer content loading={mainLoading} error={error} empty={records?.length === 0}>
        <ResponsiveTable type="primary" columns={COLUMNS} dataSource={records} />
      </BoxContainer>
    </BoxContainer>
  );
}

LocationAlerts.propTypes = {};

export default LocationAlerts;
