import React from 'react';

import { Button } from 'antd';
import PropTypes from 'prop-types';

import IntlMessages from 'util/IntlMessages';
import ProgressBar from 'components/ProgressBar';
import ExecutionStatus from 'components/Widgets/ExecutionStatus';

import styles from './styles.module.less';
import TimeExpiredWarning from './TimeExpiredWarning';
import WorkOrderMainInfo from './WorkOrderMainInfo';
import Members from 'components/MemberList/Members';
import classNames from 'classnames';

const WorkOrderInfo = ({ workOrder, onShowMore }) => {
  const {
    schedule: { name, expiredAllowedTime },
    status,
    triggersProgress,
    viewed,
    delivered,
    deliveryState,
  } = workOrder;

  const members = workOrder?.schedule?.members || [];

  return (
    <div className={styles.main}>
      <div className="gx-w-100">
        <div className={styles.titleContainer}>
          <div className="gx-flex-row gx-align-content-center">
            <div className="gx-mr-2">
              <ExecutionStatus status={status} />
            </div>
            <div className={classNames('gx-guarnic-headline-1', styles.titleContent)}>{name}</div>
          </div>
          <Button onClick={onShowMore} className="gx-p-0 gx-mb-0" type="link">
            <IntlMessages id="work.order.info.details" />
          </Button>
        </div>
        <WorkOrderMainInfo data={workOrder} />
        <TimeExpiredWarning status={status} expiredTime={expiredAllowedTime} />
        <div>
          <Members
            keyProp={workOrder.id}
            className="gx-mb-2"
            users={members?.users}
            groups={members?.groups}
            flatDeliveryState={{
              viewed,
              delivered,
              deliveryState,
            }}
          />
          <div>
            <ProgressBar percentage={triggersProgress} status={status} />
          </div>
        </div>
      </div>
    </div>
  );
};
WorkOrderInfo.defaultProps = {
  onShowMore: null,
};
WorkOrderInfo.propTypes = {
  workOrder: PropTypes.object.isRequired,
  onShowMore: PropTypes.func,
};

export default WorkOrderInfo;
