import React, { useCallback, useState } from 'react';

import { Input, notification } from 'antd';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { db } from 'firebase/firebase';
import safeExecute from 'util/safeExecute';
import IntlMessages from 'util/IntlMessages';
import BoxContainer from 'components/BoxContainer';
import { InfoCardList } from 'components/InfoCard';
import InfiniteScroll from 'components/InfiniteScroll';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import EventName from 'components/TriggersList/component/EventName';
import { collection, deleteDoc, doc, query } from 'firebase/firestore';
import {
  ALLOWED_ROLES,
  getDivsWithAccess,
  useFirestoreQueryBatchedPagination,
} from 'packages/utils';

import Divider from 'components/Divider';
import { KeyValueWidget } from 'components/KeyValueWidget';
import { getRouteToCreateEventType, getRouteToEditEventType } from '../../constants';
import DivisionName from '../../../../components/DivisionName/DivisionName';
import styles from './styles.module.less';

const EventTypesList = () => {
  const history = useHistory();
  const [refresh, setRefresh] = useState(false);
  const intl = useIntl();

  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);
  const loadedEventTypes = useSelector(({ eventTypes }) => eventTypes.eventType.save.loaded);

  const [formName, setFormName] = useState('');

  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EVENT_TYPES.LIST,
    userOrgAccess,
    userOrgDivisions,
  );
  const allowedDivsIds = allowedDivs.map(d => d.id);

  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));
  const queriesList = allowedSelectedDivs.map(divId =>
    query(collection(db, 'organizations', orgId, 'divisions', divId, 'event_types')),
  );

  const handleCreateEventType = atOrg => history.push(getRouteToCreateEventType(atOrg));

  const handleEditEventType = (eventTypeId, divId) =>
    history.push(getRouteToEditEventType(eventTypeId, divId));

  const {
    data: divEventTypes,
    loading: divEventTypesLoading,
    next,
    gotNewData,
    error,
  } = useFirestoreQueryBatchedPagination(queriesList, [
    selectedDivsIds,
    loadedEventTypes,
    refresh,
    formName,
  ]);
  const divEventTypesOrdered = divEventTypes
    .filter(type => type.name.toLowerCase().includes(formName.toLowerCase()))
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

  const handleDelete = useCallback(
    (eventTypeId, divId) => {
      safeExecute(() => {
        if (!eventTypeId) throw new Error('something it is wrong');

        deleteDoc(
          doc(db, 'organizations', orgId, 'divisions', divId, 'event_types', eventTypeId),
        ).then(() => {
          notification.success({
            message: intl.formatMessage({ id: 'general.save.successful.message' }),
            placement: 'topRight',
          });
          setRefresh(prev => !prev);
        });
      });
    },
    [intl, orgId],
  );

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          title={<Title value={<IntlMessages id="sidebar.menu.forms" />} />}
          actionButtons={[
            {
              label: <IntlMessages id="button.create" />,
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EVENT_TYPES.CREATE,
              type: 'primary',
              action: () => handleCreateEventType(),
            },
          ]}
          content={
            <KeyValueWidget
              label={<Title.Filter value={<IntlMessages id="general.form" />} />}
              value={
                <Input.Search
                  onChange={e => {
                    setTimeout(() => setFormName(e.target.value), 800);
                  }}
                  onSearch={val => setFormName(val)}
                />
              }
            />
          }
        />
      </BoxContainer>
      <BoxContainer content loading={divEventTypesLoading} error={error && !divEventTypesLoading}>
        {divEventTypesOrdered.map(type => (
          <InfoCardList
            key={type.id}
            title={
              <div className={styles.titleContainer}>
                <EventName eventColor={type.color} eventName={type.name} eventIcon={type.icon} />
                <Divider type="vertical" orientation="center" />
                <DivisionName divId={type.divId} withLabel={false} />
              </div>
            }
            actionButtons={[
              {
                action: () => handleEditEventType(type.id, type.divId),
                iconButton: 'edit',
                shape: 'circle',
                divId: type.divId,
                allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EVENT_TYPES.UPDATE,
              },
              {
                iconButton: 'delete',
                shape: 'circle',
                divId: type.divId,
                allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EVENT_TYPES.DELETE,
                confirm: {
                  title: <IntlMessages id="eventTypes.delete.message" />,
                  action: () => handleDelete(type.id, type.divId),
                },
              },
            ]}
          />
        ))}
        <InfiniteScroll condition={!divEventTypesLoading && gotNewData} callback={next} />
      </BoxContainer>
    </BoxContainer>
  );
};

export default EventTypesList;
