import {
  ACTIONS,
  getRouteToChartByDivision,
  getRouteToChartByOrg,
  getRouteToNewBoardByDivision,
  getRouteToNewBoardByOrg,
  getRouteToTableByDivision,
  getRouteToTableByOrg,
} from 'util/firebase-operations/constants';
import { createOperation } from 'util/firebase-operations/utils/createOperation';

const updateTableByOrg = async ({ data, orgId, boardId, tableId }) =>
  createOperation(getRouteToTableByOrg(orgId, boardId), tableId, data, ACTIONS.UPDATE);

const updateTableByDivision = async ({ data, orgId, divId, boardId, tableId }) =>
  createOperation(getRouteToTableByDivision(orgId, divId, boardId), tableId, data, ACTIONS.UPDATE);

const updateBoardByOrg = async ({ data, orgId, boardId }) =>
  createOperation(getRouteToNewBoardByOrg(orgId), boardId, data, ACTIONS.UPDATE);

const updateBoardByDivision = async ({ data, orgId, divId, boardId }) =>
  createOperation(getRouteToNewBoardByDivision(orgId, divId), boardId, data, ACTIONS.UPDATE);

const updateChartByDivision = async ({ data, orgId, divId, boardId, chartId }) =>
  createOperation(
    getRouteToChartByDivision(orgId, divId, boardId),
    chartId,
    data,
    ACTIONS.UPDATE,
    null,
    false,
  );

const updateChartByOrg = async ({ data, orgId, boardId, chartId }) =>
  createOperation(getRouteToChartByOrg(orgId, boardId), chartId, data, ACTIONS.UPDATE, null, false);

export {
  updateBoardByOrg,
  updateBoardByDivision,
  updateChartByDivision,
  updateChartByOrg,
  updateTableByOrg,
  updateTableByDivision,
};
