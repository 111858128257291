import { useState, useEffect, useRef } from 'react';
import { collection, query, orderBy } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import subscribeToUserMetrics from 'util/firebase-operations/users/subscribeToUserMetrics';
import useFirestoreRealtimeBatchedPagination from '../useFirestoreRealtimeBatchedPagination/useFirestoreRealtimeBatchedPagination';

const DEFAULT_METRICS_VALUE = {
  signedIn: false,
  activeTasks: 0,
  activeSchedules: 0,
  lastLocation: null,
};

/**
 * Custom hook to fetch and paginate user metrics from a Firestore collection.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {string} params.orgId - The organization ID.
 * @param {number} params.pageSize - The number of users to fetch per page.
 *
 * @returns {Object} - Returns an object containing:
 *   - {Array} data - Merged user data with metrics.
 *   - {boolean} loading - Loading state.
 *   - {Error|null} error - Error state.
 *   - {Function} next - Function to fetch the next page of users.
 *
 * @example
 * const { data, loading, error, next } = useGetUserMetricsPaginated({
 *   orgId: 'org123',
 *   pageSize: 10,
 * });
 *
 * In a nutshell, this hook is used to fetch and paginate user metrics besides only will be listenening to the users that are in the range.
 */

const useGetUserMetricsPaginated = ({ orgId, pageSize }) => {
  const metricsSubscriptionRef = useRef([]);
  const [metrics, setMetrics] = useState({});

  const {
    loading,
    data: users,
    next,
    loaded,
    error,
  } = useFirestoreRealtimeBatchedPagination(
    [query(collection(db, 'organizations', orgId, 'users'), orderBy('createdAt', 'desc'))],
    [orgId, pageSize],
    pageSize,
  );

  // NOTE: when users change we are going to subscribe metrics by user
  useEffect(() => {
    if (!loaded && users.length === 0) return;

    const unsubscribes = users.map(user => {
      if (user.id) {
        return subscribeToUserMetrics(orgId, user.id, metricsData => {
          metricsSubscriptionRef.current = {
            ...metricsSubscriptionRef.current,
            [user.id]: metricsData,
          };
          setMetrics(prevState => ({
            ...prevState,
            [user.id]: metricsData,
          }));
        });
      }
      return null;
    });

    // eslint-disable-next-line consistent-return
    return () => {
      unsubscribes.forEach(unsub => unsub && unsub());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  // NOTE: when metrics change we are going to merge the data, in a nutshell we are going to merge the users with the metrics
  const mergedData = users
    .map(user => ({
      ...user,
      metrics: metrics[user.id] || DEFAULT_METRICS_VALUE,
    }))
    .filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

  return { data: mergedData, loading, next, error };
};

export default useGetUserMetricsPaginated;
