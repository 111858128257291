import React from 'react';

import { Drawer } from 'antd';

import IntlMessages from 'util/IntlMessages';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import { useParams, useHistory } from 'react-router-dom';
import useGetLocationAlerts from 'packages/utils/hooks/collections/useGetLocationAlerts';
import Descriptions from 'packages/monitoring/components/Descriptions';

export default function LocationAlertDetails() {
  const { id = '', orgId = '' } = useParams();
  const history = useHistory();
  const { data, loading } = useGetLocationAlerts({ orgId, locationId: id });
  const descriptions = data?.descriptions || [];

  return (
    <Drawer closable={false} open footer={null}>
      <BoxContainer content fixed shadow>
        <FilterContainer
          goBack={() => history.goBack()}
          title={<Title value={<IntlMessages id="monitoring.location.alerts.details" />} />}
        />
      </BoxContainer>
      <BoxContainer content loading={loading}>
        <Descriptions data={descriptions} />
      </BoxContainer>
    </Drawer>
  );
}
