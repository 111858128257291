import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Col, Form, Input, Row, Switch } from 'antd';

import IntlMessages from 'util/IntlMessages';
import BoxContainer from 'components/BoxContainer';
import Title from 'components/BoxContainer/components/Title';
import FormSelect from 'components/Form/components/FormSelect';
import useGetEventTypes from 'packages/utils/hooks/useGetEventTypes';

import { InfoCardList } from 'components/InfoCard';
import DropDownTriggers from 'containers/DropDownTriggers';
import { SortableComponent } from 'components/Sortable/SortableComponent';
import { getTableByDivision, getTableByOrg } from 'util/firebase-operations/dashboards/charts/get';
import { useSelector } from 'react-redux';
import { SlidePanelContext } from 'packages/dashboard/components/SlidePanel/SlidePanelContext';
import useGetActiveSchedules from 'packages/utils/hooks/collections/useGetActiveSchedules';
import styles from './styles.module.less';
import {
  AUX_FIELDS_EXTRA,
  AUX_FIELDS_TABLE_FORM,
  DASHBOARDS_FORMS,
  getEventTypesFromSchedules,
  INPUT_TYPES_NOT_ALLOWED,
  LAYOUT_BY_DEFAULT,
} from '../../constants';

const TableForm = ({ formRef, tableId, boardId, divId }) => {
  const intl = useIntl();
  const orgId = useSelector(state => state.organizations.organization.id);
  const { boardSelected } = useContext(SlidePanelContext);
  const isAOrgBoard = !boardSelected?.divId;
  const columnsFixed = useMemo(() => {
    const output = [...AUX_FIELDS_TABLE_FORM];

    // NOTE: With this we are gonna add the division field in the table form only for org boards
    if (isAOrgBoard) {
      output.push({
        componentId: AUX_FIELDS_EXTRA.division,
        isActive: true,
        propertyName: <IntlMessages id="dashboards.events.table.auxFields.division" />,
        order: null,
        index: 0,
      });
    } else {
      const index = output.findIndex(field => field.componentId === AUX_FIELDS_EXTRA.division);
      if (index !== -1) {
        output.splice(index, 1);
      }
    }

    // Remove duplicate componentIds
    const uniqueOutput = output.filter(
      (field, index, self) => index === self.findIndex(f => f.componentId === field.componentId),
    );

    return uniqueOutput;
  }, [isAOrgBoard]);

  const [tableColumns, setTableColumns] = useState(() => columnsFixed);

  const [form, setForm] = useState();
  const [triggerIds, setTriggerIds] = useState([]);
  const [currentTable, setCurrentTable] = useState({});
  const [loading, setLoading] = useState(false);
  const { data: eventTypes, loading: eventLoading } = useGetEventTypes(true, divId);

  const { data: activeSchedules, loading: schedulesLoading } = useGetActiveSchedules(
    orgId,
    divId ? [divId] : [],
  );

  const eventTypesFromSchedule = getEventTypesFromSchedules({
    schedules: activeSchedules,
    formId: form,
    divId,
  });

  const divisionPerEventType = eventTypes?.find(event => event.id === form)?.divId || null;
  const mainLoading = schedulesLoading || eventLoading || loading;

  // Build columns from form selected - All components from form selected
  const buildColumns = useCallback(
    (components = []) => {
      const prevIndex = columnsFixed.length;
      const columns = components.map((component, i) => ({
        componentId: component?.id,
        isActive: !INPUT_TYPES_NOT_ALLOWED.includes(component?.type),
        propertyName: component?.label?.name,
        order: null,
        index: prevIndex + i,
        type: component?.type,
      }));

      setTableColumns(prev => [...prev, ...columns]);
    },
    [columnsFixed.length],
  );

  const buildColumnsFromDataFetched = useCallback(
    (columns, formId) => {
      const formFetched = eventTypes.find(et => et.id === formId);
      // Since we are not saving the propertyName in the column, we need to get it from the form
      const columnsFetched = columns.map(c => ({
        ...c,
        propertyName:
          formFetched.components.find(comp => comp.id === c.componentId)?.label?.name ||
          columnsFixed.find(aux => aux.componentId === c.componentId)?.propertyName,
      }));

      setTableColumns(columnsFetched);
    },
    [eventTypes, columnsFixed],
  );

  const onFormChange = useCallback(
    (formId = {}) => {
      const formSelected = eventTypes.find(et => et.id === formId);
      if (!formSelected) return;

      buildColumns(formSelected.components);
    },
    [buildColumns, eventTypes],
  );

  const onChangeTriggers = useCallback(
    triggers => {
      const newTriggersSelected = triggers.map(tr => tr.id);
      formRef.current.setFieldValue('triggerIds', newTriggersSelected);
      setTriggerIds(newTriggersSelected);
    },
    [formRef],
  );

  const onManageColumns = useCallback(
    col => {
      const newColumns = tableColumns.map(c => {
        if (c.componentId === col.componentId) {
          return { ...c, isActive: !c.isActive };
        }
        return c;
      });

      setTableColumns(newColumns);
    },
    [tableColumns],
  );

  const buildBody = useCallback(() => {
    const title = formRef.current.getFieldValue('title');
    const formId = formRef.current.getFieldValue('formId');
    // Remove type, propertyNames from columns
    const columns = tableColumns.map(record => {
      const { type, propertyName, ...newCol } = record;
      return newCol;
    });
    const body = {
      queryParams: {
        // TODO: implement "sortFields" in the future
        triggerIds,
        columns,
        formId,
      },
      layout: currentTable?.layout ?? LAYOUT_BY_DEFAULT,
      title,
    };
    if (formRef.current) {
      formRef.current.setFieldValue('tableFormData', body);
      formRef.current.setFieldValue('id', tableId);
    }
  }, [currentTable, formRef, tableColumns, tableId, triggerIds]);

  const onSortableMove = useCallback(cols => {
    // Generate Index for each column
    const columns = cols.map((column, i) => ({ ...column, index: i }));
    setTableColumns(columns);
  }, []);

  const checkingIfColumnIsAllowed = useCallback(
    componentId => {
      const element = eventTypes
        .find(et => et.id === form)
        ?.components.find(c => c.id === componentId);
      return INPUT_TYPES_NOT_ALLOWED.includes(element?.type);
    },
    [eventTypes, form],
  );

  const ColumnItem = useMemo(
    () =>
      // eslint-disable-next-line react/prop-types
      ({ data = {} }) =>
        (
          <InfoCardList
            extraRightContent={
              <div>
                <Switch
                  defaultChecked={data?.isActive}
                  checked={data?.isActive}
                  onChange={() => onManageColumns(data)}
                  disabled={checkingIfColumnIsAllowed(data?.componentId)}
                />
              </div>
            }
            title={data?.propertyName}
          />
        ),
    [checkingIfColumnIsAllowed, onManageColumns],
  );

  useEffect(() => {
    buildBody();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableColumns]);

  useEffect(() => {
    if (tableId && boardId) {
      const fetchData = async () => {
        setLoading(true);
        const res = divId
          ? await getTableByDivision({ orgId, divId, boardId, tableId })
          : await getTableByOrg({ orgId, boardId, tableId });

        if (res) setCurrentTable(res);
        setLoading(false);
      };
      fetchData();
    }
  }, [boardId, divId, orgId, tableId]);

  // This gonna control the form data fetched from the table, if we are going to edit a table we need to set the data fetched from the table
  useEffect(() => {
    if (Object.values(currentTable).length > 0) {
      const { title, queryParams } = currentTable;
      const { columns = [], triggerIds: triggersIdsFetched = [], formId = {} } = queryParams;

      if (tableColumns.length === columnsFixed.length) {
        buildColumnsFromDataFetched(columns, formId);
        formRef.current.setFieldsValue({
          title,
          formId,
          triggerIds: triggersIdsFetched,
          id: tableId,
        });
        setTriggerIds(triggersIdsFetched);
        setForm(formId);
      }
    }
  }, [
    buildColumns,
    buildColumnsFromDataFetched,
    columnsFixed.length,
    currentTable,
    formRef,
    tableColumns.length,
    tableId,
  ]);

  return (
    <Form
      onFieldsChange={buildBody}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 24 }}
      ref={formRef}
      name={DASHBOARDS_FORMS.TABLE}
      initialValues={{
        id: tableId ?? null,
        formId: form ?? null,
        tableFormData: {},
        title: formRef.current?.getFieldValue('title') ?? '',
        triggerIds: triggerIds ?? [],
      }}
    >
      <BoxContainer content loading={mainLoading}>
        <Row gutter={16}>
          <Col span={24}>
            <Title.LabelForm
              required
              value={<IntlMessages id="dashboards.events.table.form.table.name" />}
            />
            <Form.Item
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'form.required',
                  }),
                },
              ]}
              name="title"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title.LabelForm required value={<IntlMessages id="dashboards.chart.form.form" />} />
            <Form.Item
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'form.required',
                  }),
                },
              ]}
              name="formId"
            >
              <FormSelect
                onChange={val => {
                  formRef.current.setFieldValue('triggerIds', []);
                  setTriggerIds([]);
                  setTableColumns(columnsFixed); // Reset columns since form changed, and build new columns (event columns)

                  setForm(val);
                  onFormChange(val);
                }}
                loading={eventLoading}
                options={eventTypes}
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="triggerIds">
              <Title.LabelForm value={<IntlMessages id="general.eventTypes" />} />
              <DropDownTriggers
                mode="multiple"
                allowClear
                onChange={onChangeTriggers}
                division={divisionPerEventType}
                eventType={form}
                defaultValue={triggerIds}
                value={triggerIds}
                onClear={() => {
                  formRef.current.setFieldValue('triggerIds', []);
                  setTriggerIds(null);
                }}
                extraTriggers={eventTypesFromSchedule}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title.SubTitle value={<IntlMessages id="dashboards.events.table.form.subtitle" />} />
            <p className="gx-m-0">
              <IntlMessages id="dashboards.events.table.form.description" />
            </p>
            <div className={styles.notAllowedMessage}>
              <span className="material-symbols-outlined">info</span>
              <span>
                <IntlMessages id="dashboards.events.table.form.notAllowed" />
              </span>
            </div>
            <SortableComponent
              items={tableColumns}
              Component={ColumnItem}
              onMove={onSortableMove}
              zIndex={1000}
            />
          </Col>
        </Row>
      </BoxContainer>
      <Form.Item name="tableFormData" hidden />
      <Form.Item name="id" hidden />
    </Form>
  );
};

TableForm.propTypes = {
  formRef: PropTypes.object,
  tableId: PropTypes.string,
  boardId: PropTypes.string,
  divId: PropTypes.string,
};

export default TableForm;
