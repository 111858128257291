import React from 'react';
import { DELIVERY_STATUS } from './constants';
import classNames from 'classnames';
import styles from './styles.module.less';

function DeliveryStatus({ status = DELIVERY_STATUS.SENT }) {
  const STATUS = {
    [DELIVERY_STATUS.SENT]: <span className="material-symbols-outlined gx-text-grey">check</span>,
    [DELIVERY_STATUS.DELIVERED]: (
      <span className="material-symbols-outlined gx-text-grey">done_all</span>
    ),
    [DELIVERY_STATUS.SEEN]: (
      <span className={classNames('material-symbols-outlined', styles.seenStatus)}>done_all</span>
    ),
  };

  return <div className="gx-align-content-center">{STATUS[status]}</div>;
}

export default React.memo(DeliveryStatus);
