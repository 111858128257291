import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import IntlMessages from 'util/IntlMessages';

import styles from './styles.module.less';

const DivisionName = ({ divId, withLabel = true }) => {
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const division = useSelector(
    ({ user }) => user.profile.data.organizations[orgId].divisions[divId]?.name,
  );

  return (
    <div className="gx-d-flex gx-align-items-center">
      {withLabel && (
        <h1 className={classnames('gx-mr-3', styles.divTitle)}>
          <IntlMessages id="general.division" />:
        </h1>
      )}
      <h1 className={classnames(styles.divTitle, styles.division)}>{division}</h1>
    </div>
  );
};

DivisionName.propTypes = {
  divId: PropTypes.string.isRequired,
  withLabel: PropTypes.bool,
};

export default DivisionName;
