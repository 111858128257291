import React from 'react';

import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Button, Input } from 'antd';

import IntlMessages from 'util/IntlMessages';
import { KeyValueWidget } from 'components/KeyValueWidget';
import Title from 'components/BoxContainer/components/Title';

import styles from './styles.module.less';

function LocationAlertForm({ onSubmit, onChange, loading }) {
  const intl = useIntl();
  return (
    <div className={styles.locationAlertForm}>
      <div className="gx-w-100 gx-h-100 gx-flex-row gx-align-items-center">
        <div className="gx-w-100 gx-flex-column gx-align-items-center">
          <div className={styles.locationAlertFormIcon}>
            <span className="material-symbols-outlined">article</span>
          </div>
          <div className="gx-mb-3 gx-text-center">
            <p className={classNames('gx-fs-xl', styles.formTitle)}>
              <IntlMessages id="location.alert.form.title" />
            </p>
            <p className="gx-fs-md">
              <IntlMessages id="location.alert.form.subtitle" />
            </p>
          </div>

          <div className="gx-w-100">
            <KeyValueWidget
              label={
                <div className={styles.descriptionTitle}>
                  <Title.LabelForm value={<IntlMessages id="location.alert.description.title" />} />
                  <span className="gx-fs-xs">
                    <IntlMessages id="location.alert.description.title.optional" />
                  </span>
                </div>
              }
              value={
                <Input.TextArea
                  onChange={e => onChange(e.target.value)}
                  placeholder={intl.formatMessage({
                    id: 'location.alert.description.placeholder',
                  })}
                  size="large"
                  maxLength={500}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                />
              }
            />
          </div>
        </div>
      </div>
      <div>
        <Button disabled={loading} block onClick={onSubmit} type="primary">
          <IntlMessages id="button.send" />
        </Button>
      </div>
    </div>
  );
}

export default React.memo(LocationAlertForm);
