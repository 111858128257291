import { ACTIONS } from './actions';

export const getInitialState = () => ({
  dataByBatch: {},
  loadedByBatch: { 0: false },
  loadingByBatch: { 0: false },
  gotNewDataByBatch: { 0: true },
  error: null,
  next: null,
});

export const reducer = (state = getInitialState(), { type, payload }) => {
  switch (type) {
    case ACTIONS.UTI_FRB_FETCH: {
      const updatedLoaded = { ...new Array(payload.batchesAmount).fill(false) };
      const updatedLoading = { ...new Array(payload.batchesAmount).fill(true) };

      return {
        ...getInitialState(),
        loadedByBatch: updatedLoaded,
        loadingByBatch: updatedLoading,
      };
    }
    case ACTIONS.UTI_FRB_FETCH_ERROR: {
      return {
        ...getInitialState(),
        error: payload,
        loadedByBatch: { 0: true },
      };
    }
    case ACTIONS.UTI_FRB_FETCH_BATCH_SUCCESS: {
      const { batchId, documentIdsDeleted } = payload;

      // NOTE: we are to delete the document from the state, of this way we can return the state updated.
      const prevStateByBatch = state.dataByBatch[batchId] || {};
      documentIdsDeleted.forEach(id => {
        delete prevStateByBatch[id];
      });

      const prevDocIds = Object.keys(prevStateByBatch || {});
      const newDocIds = Object.keys(payload.data);
      const isSnapUpdate = newDocIds.some(id => prevDocIds.includes(id));
      const hasNewDocs = !newDocIds.every(id => prevDocIds.includes(id));

      const updatedData = {
        ...state.dataByBatch,
        [batchId]: { ...prevStateByBatch, ...payload.data },
      };
      const updatedGotNew = {
        ...state.gotNewDataByBatch,
        [batchId]: isSnapUpdate ? state.gotNewDataByBatch : hasNewDocs,
      };
      const updatedLoading = {
        ...state.loadingByBatch,
        [batchId]: false,
      };
      const updatedLoaded = {
        ...state.loadedByBatch,
        [batchId]: true,
      };

      return {
        ...getInitialState(),
        dataByBatch: updatedData,
        gotNewDataByBatch: updatedGotNew,
        loadedByBatch: updatedLoaded,
        loadingByBatch: updatedLoading,
      };
    }
    case ACTIONS.UTI_FRB_RESET: {
      return getInitialState();
    }
    default:
      throw new Error('useFirestoreQueryBatched: Invalid action');
  }
};
