import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DatePicker, Divider, InputNumber, Select, Radio } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { functions, auth } from 'firebase/firebase';
import { httpsCallable } from 'firebase/functions';
import IntlMessages from 'util/IntlMessages';

import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import TimelineEventsDrawer from 'packages/events/screens/TimelineEventDrawer';
import useGetParams from 'packages/utils/hooks/useGetParams';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getFullUrl } from 'packages/utils';
import {
  getRouteToDashboardSchedules,
  getRouteToEventTypesDrawer,
  getRouteToTimelineEventDrawer,
} from 'packages/dashboard/constants';
import EventTypesDrawer from 'packages/events/components/EventTypesDrawer';
import { CLOUD_FUNCTIONS } from 'util/cloud-functions/constants';
import { get, set } from 'packages/utils/storage';
import { KeyValueWidget } from 'components/KeyValueWidget';
// import { SCHEDULE_TYPES } from 'constants/commons';
import styles from './styles.module.less';
import ColumnChart from './components/ColumnChart';
import SchedulesSelector from './components/ScheduleSelector';
import { camelize, getDateTypeIndex, getRange } from '../../utils';

import { dateTypes } from './constants';
import ProgressItemV2 from './components/ProgressItemV2';

const { RangePicker } = DatePicker;
const { Option } = Select;

const SchedulesDashboard = () => {
  const stateFromStorage = get('schedulesDashboardFilters');
  const intl = useIntl();
  const groupBy = useRef(stateFromStorage?.groupBy ?? 'day');

  const [aggregations, setAggregations] = useState([]);
  const [isRelative, setIsRelative] = useState(() => stateFromStorage?.isRelative ?? true);
  const [relativeAmount, setRelativeAmount] = useState(() => stateFromStorage?.relativeAmount ?? 1);
  const [dateType, setDateType] = useState(() => stateFromStorage?.dateType ?? 'day');
  const [dateRange, setDateRange] = useState(
    () =>
      [moment(stateFromStorage?.dateRange[0]), moment(stateFromStorage?.dateRange[1])] ?? [
        moment().subtract(6, 'month'),
        moment(),
      ],
  );
  const [selectedSchedules, setSelectedSchedules] = useState({ byId: {}, selectedAll: false });
  const [loading, setLoading] = useState(false);
  const [scheduleSelectorLoading, setScheduleSelectorLoading] = useState(false);

  const selectedDivs = useSelector(({ divisions }) => divisions.selector.ids || []);
  const orgId = useSelector(state => state.organizations.organization.id);
  const q = useGetParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { location: { state: { shadow = false } = {} } = {} } = history || {};

  const divId = q.get('divId');
  const triggerId = q.get('triggerId')?.replace('/events', '');
  const scheduleId = q.get('scheduleId');
  const showEventTypes = !!scheduleId?.includes('/event_types'); // NOTE: control event types drawer visibility
  const openTimelineEvents = !!divId && !!triggerId; // NOTE: control events drawer visibility

  const addMessage = useCallback(
    () => httpsCallable(functions, CLOUD_FUNCTIONS.SCHEDULE_DASHBOARD),
    [],
  );

  // Can't select dates after today
  const disabledDate = date => moment().isBefore(date);
  const dateTypeOptions = [
    {
      label: intl.formatMessage({ id: 'dashboards.schedules.relative' }),
      value: true,
    },
    {
      label: intl.formatMessage({ id: 'dashboards.schedules.absolute' }),
      value: false,
    },
  ];

  const fetch = useCallback(() => {
    const isValidRequest = !!orgId && !!selectedDivs.length;
    if (!isValidRequest) {
      return;
    }

    const paramsSent = {
      organizationId: orgId,
      divisionIds: selectedDivs,
      uid: auth.currentUser.uid,
      range: getRange(dateRange, dateType, isRelative, relativeAmount),
      interval: groupBy.current,
      scheduleIds: selectedSchedules.selectedAll ? [] : Object.keys(selectedSchedules.byId),
      // type: SCHEDULE_TYPES.schedule, // TODO: workaround , add  this when the backend is ready
    };

    setLoading(true);
    addMessage()(paramsSent)
      .then(result => {
        const { data } = camelize(result);
        setAggregations(data.aggregations.timeRange.histogram.buckets);
        setLoading(false);
      })
      .catch(() => {
        setAggregations([]);
        setLoading(false);
      });
  }, [
    addMessage,
    dateRange,
    dateType,
    isRelative,
    orgId,
    relativeAmount,
    selectedDivs,
    selectedSchedules.byId,
    selectedSchedules.selectedAll,
  ]);

  const onOpenEventTypesDrawer = ({ schedule = {} }) => {
    const range = getRange(dateRange, dateType, isRelative, relativeAmount);

    const path = getFullUrl(
      getRouteToEventTypesDrawer(
        schedule.divId,
        range.gte,
        range.lte,
        groupBy.current,
        schedule.id,
      ),
      url,
    );

    history.push(path, {
      shadow: true,
    });
  };

  const goToTimeline = (divisionId, schId, trId) => {
    const range = getRange(dateRange, dateType, isRelative, relativeAmount);

    const path = getFullUrl(
      getRouteToTimelineEventDrawer(orgId, divisionId, trId, schId, range.gte, range.lte),
      url,
    );

    history.push(path, {
      shadow: true,
    });
  };

  const setLocalStorage = useCallback(() => {
    set('schedulesDashboardFilters', {
      isRelative,
      relativeAmount,
      dateRange,
      dateType,
      groupBy: groupBy.current,
    });
  }, [isRelative, relativeAmount, dateRange, dateType]);

  const onBackFromTimeline = useCallback(() => {
    // NOTE: go back to the previous page but if it was opened from a shadow, go back to the dashboard (because shadow control if the page is sharing or not)
    if (shadow) {
      history.goBack();
    } else {
      history.push(getFullUrl(getRouteToDashboardSchedules(), url));
    }
  }, [shadow, history, url]);

  const mainLoading = loading || scheduleSelectorLoading;

  // Only re-fetch when the selected schedules change
  useEffect(() => {
    if (Object.keys(selectedSchedules.byId).length > 0 && !showEventTypes) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchedules, showEventTypes]);

  useEffect(() => {
    setLocalStorage();
  }, [isRelative, dateType, relativeAmount, dateRange, groupBy, setLocalStorage, dateRange]);

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          showHide
          actionButtons={[
            {
              label: <IntlMessages id="form.runQuery" />,
              action: () => fetch(),
              type: 'primary',
              disabled: mainLoading,
            },
          ]}
          title={<Title value={<IntlMessages id="dashboards.schedules.title" />} />}
          content={
            <div className="gx-flex-row gx-justify-content-between gx-guarnic-gap-1">
              <KeyValueWidget
                label={
                  <Title.Filter value={<IntlMessages id="dashboards.schedules.dateRange" />} />
                }
                value={
                  <>
                    <Radio.Group
                      options={dateTypeOptions}
                      onChange={e => setIsRelative(e.target.value)}
                      value={isRelative}
                      optionType="button"
                      className="gx-mr-3"
                    />

                    {isRelative ? (
                      <InputNumber min={1} value={relativeAmount} onChange={setRelativeAmount} />
                    ) : (
                      <span className="gx-mr-2">
                        <IntlMessages id="dashboards.schedules.between" />
                      </span>
                    )}

                    <Select
                      className="gx-mr-2"
                      value={dateType}
                      onChange={setDateType}
                      dropdownMatchSelectWidth={false}
                    >
                      {dateTypes.map(d => (
                        <Option key={d} value={d}>
                          <IntlMessages id={`dateTypes.${d}`} values={{ amount: relativeAmount }} />
                        </Option>
                      ))}
                    </Select>

                    {isRelative ? (
                      <span>
                        <IntlMessages id="dashboards.schedules.beforeUntilToday" />
                      </span>
                    ) : (
                      <RangePicker
                        value={dateRange}
                        picker={dateType === 'day' ? 'date' : dateType}
                        onChange={setDateRange}
                        disabledDate={disabledDate}
                      />
                    )}
                  </>
                }
              />
              <KeyValueWidget
                label={
                  <Title.Filter value={<IntlMessages id="dashboards.schedules.groupResultsBy" />} />
                }
                value={
                  <Select
                    id="groupBy"
                    onChange={v => {
                      groupBy.current = v;
                      setLocalStorage();
                    }}
                    className="gx-mr-5"
                    defaultValue={groupBy.current}
                    dropdownMatchSelectWidth={false}
                  >
                    {dateTypes.map(d => (
                      <Option key={d} value={d}>
                        <IntlMessages id={`dateTypes.${d}`} values={{ amount: 1 }} />
                      </Option>
                    ))}
                  </Select>
                }
              />
              <KeyValueWidget
                label={<Title.Filter value={<IntlMessages id="dashboards.schedules" />} />}
                value={
                  <SchedulesSelector
                    selectedSchedules={selectedSchedules}
                    setSelected={setSelectedSchedules}
                    setLoading={setScheduleSelectorLoading}
                  />
                }
              />
            </div>
          }
        />
      </BoxContainer>
      <BoxContainer loading={mainLoading} content>
        <ColumnChart data={aggregations} schedulesById={selectedSchedules.byId} />
        <Divider className="gx-mt-5 gx-mb-5" />
        <div className={styles.progressItemsList}>
          {aggregations
            .slice()
            .reverse()
            .map(day => {
              const hasSchedule = !!day.bySchedule.buckets.length;
              return (
                hasSchedule && (
                  <div key={day.key} className={styles.dayContainer}>
                    <h2 className={styles.day}>
                      {getDateTypeIndex(day.key, groupBy.current, intl)}
                    </h2>
                    {day.bySchedule.buckets.map(schAggregation => {
                      const sch = selectedSchedules.byId[schAggregation.key];
                      if (!sch) return null;
                      return (
                        <ProgressItemV2
                          key={schAggregation.key}
                          schedule={sch}
                          aggregation={schAggregation}
                          onOpenEventTypes={onOpenEventTypesDrawer}
                          onOpenTimeline={goToTimeline}
                        />
                      );
                    })}
                  </div>
                )
              );
            })}
        </div>
      </BoxContainer>
      {openTimelineEvents && (
        <TimelineEventsDrawer isVisible={openTimelineEvents} onCancel={onBackFromTimeline} />
      )}
      {showEventTypes && <EventTypesDrawer isVisible={showEventTypes} />}
    </BoxContainer>
  );
};

export default SchedulesDashboard;
