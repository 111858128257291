import React from 'react';

import { Card } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { generateKey } from 'packages/utils';
import ComponentBlocker from 'components/ComponentBlocker';
import CircleButton from 'components/Buttons/CircleButton';

import styles from './styles.module.less';

function DashboardCard({ children, className, title, subTitle, actionButtons }) {
  const getCircleButtons = () =>
    actionButtons?.map((bt, idx) => {
      if (bt?.hidden) return null;

      return (
        <React.Fragment key={generateKey(idx)}>
          {bt?.allowedRole && bt?.allowedRole.length > 0 ? (
            <ComponentBlocker allowedRoles={bt.allowedRole} divId={bt?.divId}>
              <CircleButton
                iconName={bt?.iconName}
                onClick={bt?.action}
                disabled={bt?.disabled || false}
                hidden={bt?.hidden || false}
                label={bt?.label}
                tooltipText={bt?.tooltipText}
                type={bt?.type}
                iconClassName={bt?.iconClassName}
                iconNameText={bt?.iconNameText}
                iconSize={bt?.iconSize}
              />
            </ComponentBlocker>
          ) : (
            <CircleButton
              iconName={bt?.iconName}
              onClick={bt?.action}
              disabled={bt?.disabled || false}
              hidden={bt?.hidden || false}
              label={bt?.label}
              tooltipText={bt?.tooltipText}
              type={bt?.type}
              iconClassName={bt?.iconClassName}
              iconNameText={bt?.iconNameText}
              iconSize={bt?.iconSize}
            />
          )}
        </React.Fragment>
      );
    });

  return (
    <Card className={classNames(styles.card, className)}>
      <div className="gx-d-flex gx-flex-column gx-h-100">
        <div className={styles.topContainer}>
          <div className="gx-flex-column gx-overflow-auto">
            {title && <span className={styles.title}>{title}</span>}
            {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
          </div>
          {actionButtons?.length > 0 && (
            <div className={classNames('cancelDrag', styles.dashboardCardButtons)}>
              {getCircleButtons()}
            </div>
          )}
        </div>
        {children}
      </div>
    </Card>
  );
}

DashboardCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string,
      action: PropTypes.func,
      disabled: PropTypes.bool,
      hidden: PropTypes.bool,
      label: PropTypes.string,
      tooltipText: PropTypes.string,
      type: PropTypes.string,
      iconClassName: PropTypes.string,
      iconNameText: PropTypes.string,
      allowedRole: PropTypes.arrayOf(PropTypes.string),
      divId: PropTypes.string,
    }),
  ),
};

export default React.memo(DashboardCard);
