import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styles from './styles.module.less';
import { arrayMove } from 'packages/utils';
import classNames from 'classnames';
import { MenuOutlined } from '@ant-design/icons';

const SortableItem = SortableElement(({ value, sortIndex, zIndex }) => (
  <li
    style={{ listStyle: 'none', zIndex, position: zIndex ? 'relative' : 'static' }}
    tabIndex={sortIndex}
  >
    {value}
  </li>
));

const SortableList = SortableContainer(({ items, dragDisabled, zIndex }) => (
  <ul className={styles.sorteableList}>
    {items?.map((value, index) => (
      <SortableItem
        disabled={dragDisabled}
        key={index}
        index={index}
        value={value}
        sortIndex={index}
        zIndex={zIndex}
      />
    ))}
  </ul>
));

/**
 * SortableComponent is a memoized React component that renders a sortable list of items.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.items - The array of items to be rendered.
 * @param {Function} props.onMove - The function to handle item movement.
 * @param {React.Component} props.Component - The component to render for each item.
 * @param {Array} props.extraProps - The array of extra props to be passed to the component.
 * @param {string} props.className - The CSS class name for the container element.
 * @param {Object} props.rest - The rest of the props.
 * @returns {React.Component} The rendered SortableComponent.
 */
export const SortableComponent = React.memo(
  ({ items, onMove, Component, extraProps = [], className, zIndex, ...rest }) => {
    const [dragDisabled, setDragDisabled] = useState(true);

    // onMove method will help to order by index when we are using drag and drop
    const handleSortEnd = ({ oldIndex, newIndex }) => {
      const updatedRecords = arrayMove(items, oldIndex, newIndex);
      onMove(updatedRecords);
      setDragDisabled(true);
    };

    const onDragDisabled = () => {
      setDragDisabled(false);
    };

    const buildExtraProps = record => {
      if (!record) return {};
      const props = extraProps.reduce((acc, prop) => {
        const { name, action, args = [] } = prop;
        const recordLikeArg = args && args.length === 0;
        const argsParsed = args?.map(argName => record[argName]);

        if (name && action) {
          if (recordLikeArg) {
            acc[name] = () => action(record);
          } else {
            acc[name] = () => action(...argsParsed);
          }
        }

        return acc;
      }, {});

      return props;
    };

    const newItems = items?.map(item => (
      <div className={classNames('gx-w-100 gx-d-flex', className)}>
        <MenuOutlined
          className="gx-mr-3"
          onTouchStartCapture={() => onDragDisabled()}
          onMouseMove={() => onDragDisabled()}
        />
        <Component {...rest} data={item} {...buildExtraProps(item)} />
      </div>
    ));

    return (
      <SortableList
        zIndex={zIndex}
        dragDisabled={dragDisabled}
        onSortStart={(_, e) => {
          e.preventDefault();
          onDragDisabled();
        }}
        onSortEnd={(data, e) => {
          e.preventDefault();
          handleSortEnd(data);
        }}
        items={newItems}
      />
    );
  },
);

SortableComponent.propTypes = {
  items: PropTypes.array.isRequired,
  onMove: PropTypes.func.isRequired,
  Component: PropTypes.any.isRequired,
  extraProps: PropTypes.array,
  className: PropTypes.string,
};
