import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

export const BaseLayers = ({
  layer = 'OpenStreetMap',
  defaultLayer = 'OpenStreetMap',
  onActiveLayer = null,
}) => {
  const map = useMap();

  const baseLayers = {
    OpenStreetMap: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }),
    TopoMap: L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
      maxZoom: 17,
      attribution:
        'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    }),
    Dark: L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20,
    }),
  };

  useEffect(() => {
    if (map && onActiveLayer) {
      const layersControl = L.control.layers(baseLayers);
      layersControl?.addTo(map);

      const initialLayer = baseLayers[defaultLayer];
      initialLayer?.addTo(map);
      onActiveLayer(defaultLayer);
    }

    map.on('baselayerchange', e => {
      baseLayers[layer]?.addTo(map);
      onActiveLayer(e.name);
    });
  }, [map, defaultLayer, onActiveLayer]);

  return null;
};

export default BaseLayers;
