import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Progress, Select } from 'antd';
import { getOptionTimer } from './constants';
import styles from './styles.module.less';
import { SyncOutlined } from '@ant-design/icons';
import useStopWatch from '../../packages/utils/hooks/useStopWatch';
import { useIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
import classnames from 'classnames';

const { Option } = Select;

/**PARAMS / PROPS
 * initValue : must be minutes / it is default value
 * times: must be an array of numbers , it going to manage the timer options
 * addManualRefresh: work to add manual button
 */

const SelectRefreshTimer = ({
  className,
  addManualRefresh,
  onRefresh,
  times,
  initValue,
  size,
  manualButtonPos = 'left',
  forcePauseTimer = false,
}) => {
  const [timeSelected, setTimeSelected] = useState(initValue);
  const intl = useIntl();
  const placeholder = intl.formatMessage(
    { id: 'component.selectRefreshTimer.placeholder' },
    { time: timeSelected },
  );
  const timeInSeconds = timeSelected * 60;
  const OptionTimer = getOptionTimer(times);

  const {
    time: stopWatchTimer,
    resetTime,
    initTime,
    pauseTime,
    resumeTime,
  } = useStopWatch(timeInSeconds);

  const timeInProgress = (stopWatchTimer / timeInSeconds) * 100;

  const handleChange = minutes => {
    setTimeSelected(minutes);
    onRefresh(true);
    resetTime();
  };

  useEffect(() => {
    initTime();
  }, []);

  useEffect(() => {
    if (timeInProgress === 100) onRefresh(true);
    if (timeInProgress === 0) onRefresh(false);
  }, [timeInProgress]);

  useEffect(() => {
    if (forcePauseTimer) {
      pauseTime();
    } else {
      resumeTime();
    }
  }, [forcePauseTimer]);

  return (
    <div style={{ width: size }} className={classnames(styles.container, className)}>
      {addManualRefresh && manualButtonPos === 'left' && (
        <div className={classnames(styles.manualRefreshButton)}>
          <Button
            onClick={() => onRefresh(true)}
            className={styles.manualRefreshButton}
            icon={<SyncOutlined />}
          />
        </div>
      )}
      <div className={styles.manualButtonC}>
        <div>
          <div className="gx-w-100 gx-flex-1">
            <Select
              onChange={e => handleChange(Number(e))}
              placeholder={placeholder}
              style={{ width: '100%' }}
            >
              {OptionTimer.map(({ label, value }) => (
                <Option key={value}>
                  <IntlMessages
                    id="component.selectRefreshTimer.placeholder"
                    values={{ time: label }}
                  />
                </Option>
              ))}
            </Select>
          </div>
          <div className={classnames('gx-w-100 gx-flex-1', styles.progressBarC)}>
            <Progress
              className={styles.customProgress}
              showInfo={false}
              percent={timeInProgress}
              status="active"
            />
          </div>
        </div>
      </div>
      {addManualRefresh && manualButtonPos === 'right' && (
        <div className={classnames(styles.manualRefreshButton)}>
          <Button
            onClick={() => onRefresh(true)}
            className={styles.manualRefreshButton}
            icon={<SyncOutlined />}
          />
        </div>
      )}
    </div>
  );
};

SelectRefreshTimer.defaultProps = {
  className: null,
  addManualRefresh: false,
  size: null,
};
SelectRefreshTimer.propTypes = {
  className: PropTypes.string,
  addManualRefresh: PropTypes.bool,
  onRefresh: PropTypes.func.isRequired,
  times: PropTypes.arrayOf(PropTypes.number),
  initValue: PropTypes.number,
  size: PropTypes.string,
};

export default SelectRefreshTimer;
