import React from 'react';

import PropTypes from 'prop-types';
import { Col, Input, Row } from 'antd';

import IntlMessages from 'util/IntlMessages';
import BoxContainer from 'components/BoxContainer';
import { KeyValueWidget } from 'components/KeyValueWidget';
import Title from 'components/BoxContainer/components/Title';

function LocationAlertFilters({ onFilters }) {
  return (
    <BoxContainer content>
      <Row justify="start">
        <Col xs={24} sm={5} md={5} xl={5} xxl={5}>
          <KeyValueWidget
            label={
              <Title.Filter
                value={<IntlMessages id="monitoring.location.alerts.details.filter.locationName" />}
              />
            }
            value={
              <Input
                onChange={e => {
                  onFilters(prev => ({
                    ...prev,
                    name: e.target.value,
                  }));
                }}
                className="gx-w-100"
              />
            }
          />
        </Col>
      </Row>
    </BoxContainer>
  );
}

LocationAlertFilters.propTypes = {
  onFilters: PropTypes.func.isRequired,
};

export default React.memo(LocationAlertFilters);
