import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col, Drawer, Form, Input, notification, Row } from 'antd';

import safeExecute from 'util/safeExecute';
import IntlMessages from 'util/IntlMessages';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import { KeyValueWidget } from 'components/KeyValueWidget';
import DropDownDivisions from 'components/DropDownDivision';
import Title from 'components/BoxContainer/components/Title';
import useGetDivisionsAllowed from 'packages/utils/hooks/useGetDivisionsAllowed';
import { createBoard, updateBoard } from 'packages/dashboard/screens/EventsDashboard/formHandlers';

import { ALLOWED_ROLES } from 'packages/utils';
import { SlidePanelContext } from '../SlidePanel/SlidePanelContext';

export default function BoardForm({ filters }) {
  const [loading, setLoading] = React.useState(false);
  const {
    openBoardForm,
    onCloseBoardForm,
    boardSelected,
    boardIsEdit,
    onBoardChange,
    isBoardTypeDiv,
  } = useContext(SlidePanelContext);
  const orgId = useSelector(state => state.organizations.organization.id);
  const { allowedDivs } = useGetDivisionsAllowed(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.DASHBOARDS.CREATE,
  );
  const [form] = Form.useForm();
  const intl = useIntl();

  const onSave = (_, { values }) => {
    safeExecute(async () => {
      let body = {
        name: values.name,
        queryParams: {
          interval: filters.groupBy,
          range: {
            dateRange: filters?.dateRange?.map(d => {
              if (typeof d === 'string') {
                return new Date(d);
              }
              return d.toDate();
            }),
            dateType: filters?.relative?.dateType,
            isRelative: filters?.relative?.isRelative,
            relativeAmount: filters?.relative?.relativeAmount,
          },
        },
      };
      if (values?.divId) {
        body = { ...body, divId: values.divId };
      }
      setLoading(true);

      if (boardIsEdit) {
        await updateBoard({
          data: body,
          orgId,
          divId: values.divId || boardSelected.divId,
          boardId: boardSelected.id,
        });
      } else {
        const res = await createBoard({ data: body, orgId, divId: values.divId });
        onBoardChange(res);
      }

      notification.success({
        message: intl.formatMessage({ id: 'general.save.successful.message' }),
      });
      onCloseBoardForm();
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (boardIsEdit) {
      form.setFieldsValue({
        name: boardSelected.name,
        divId: boardSelected.divId,
      });
    } else {
      form.setFieldsValue({
        name: null,
        divId: null,
      });
    }
  }, [boardIsEdit, boardSelected.divId, boardSelected.name, form]);

  return (
    <Drawer open={openBoardForm} closable={false} footer={null}>
      <Form.Provider onFormFinish={onSave}>
        <Form
          initialValues={{ name: null, divId: null, id: null }}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 6 }}
          form={form}
          name="boards"
        >
          <BoxContainer>
            <BoxContainer shadow fixed content>
              <FilterContainer
                title={
                  <Title.Header
                    value={<IntlMessages id="dashboards.metrics.boards.form.add.board" />}
                  />
                }
                goBack={onCloseBoardForm}
                buttonItems={[
                  {
                    iconName: 'save',
                    type: 'primary',
                    action: form.submit,
                    disabled: loading,
                  },
                ]}
              />
            </BoxContainer>
          </BoxContainer>
          <BoxContainer content loading={loading}>
            <Row gutter={[8, 8]}>
              {isBoardTypeDiv && (
                <Col span={24}>
                  <KeyValueWidget
                    label={
                      <Title.LabelForm required value={<IntlMessages id="common.division" />} />
                    }
                    value={
                      <Form.Item
                        rules={
                          boardIsEdit
                            ? []
                            : [
                                {
                                  required: true,
                                  message: intl.formatMessage({ id: 'form.required.msg' }),
                                },
                                // eslint-disable-next-line react/jsx-indent
                              ]
                        }
                        name="divId"
                      >
                        <DropDownDivisions
                          disabled={boardIsEdit}
                          options={allowedDivs}
                          placeholder={<IntlMessages id="common.division" />}
                        />
                      </Form.Item>
                    }
                  />
                </Col>
              )}
              <Col span={24}>
                <KeyValueWidget
                  label={
                    <Title.LabelForm
                      required
                      value={<IntlMessages id="dashboards.metrics.boards.form.name" />}
                    />
                  }
                  value={
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({ id: 'form.required.msg' }),
                        },
                      ]}
                      required
                      name="name"
                    >
                      <Input />
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
          </BoxContainer>
          <Form.Item hidden name="id" />
        </Form>
      </Form.Provider>
    </Drawer>
  );
}

BoardForm.propTypes = {
  filters: PropTypes.shape({
    groupBy: PropTypes.string,
    dateRange: PropTypes.arrayOf(PropTypes.object),
    relative: PropTypes.shape({
      dateType: PropTypes.string,
      isRelative: PropTypes.bool,
      relativeAmount: PropTypes.number,
    }),
  }).isRequired,
};
