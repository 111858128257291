import React, { useContext } from 'react';

import CircleButton from 'components/Buttons/CircleButton';
import IntlMessages from 'util/IntlMessages';
import { ALLOWED_ROLES, useIsMobile } from 'packages/utils';
import ComponentBlocker from 'components/ComponentBlocker';
import styles from './styles.module.less';
import { SlidePanelContext } from './SlidePanelContext';
import SlideElement from './SlideElement';

export default function SlidePanel() {
  const {
    isOpen,
    toggleSlidePanel,
    onOpenBoardForm,
    boardsByOrg,
    onBoardChange,
    boardSelected,
    boardsByDiv,
  } = useContext(SlidePanelContext);
  const { isMobile } = useIsMobile();

  return (
    <div className={isOpen ? styles.slidePanelContainer : styles.slidePanelContainerHidden}>
      {!isOpen && (
        <div className="gx-w-100 gx-h-100 gx-flex-row gx-justify-content-center">
          <div className="gx-h-100 gx-w-100 gx-align-content-center">
            {isMobile ? (
              <div className="gx-flex-row">
                <div className="gx-flex-row gx-align-content-center gx-justify-content-start gx-flex-1 gx-pl-3">
                  <span className="gx-text-center gx-guarnic-headline-1">
                    <IntlMessages id="dashboards.metrics.boards.title" />
                  </span>
                </div>
                <div className="gx-flex-row gx-justify-content-end gx-guarnic-gap-2 gx-pr-3">
                  <CircleButton
                    onClick={() => toggleSlidePanel(false)}
                    className={styles.slideOpenButton}
                    iconName="arrow_menu_open"
                    type="secondary"
                  />
                </div>
              </div>
            ) : (
              <CircleButton
                onClick={() => toggleSlidePanel(false)}
                className={styles.slideOpenButton}
                iconName="arrow_menu_open"
                type="secondary"
              />
            )}
          </div>
        </div>
      )}
      {isOpen && (
        <>
          <div className={styles.slideHeader}>
            <div className="gx-flex-row">
              <div className="gx-align-content-center gx-flex-1">
                <span className="gx-text-center gx-guarnic-headline-1">
                  <IntlMessages id="dashboards.metrics.boards.title" />
                </span>
              </div>
              <div className="gx-flex-row gx-align-content-center gx-flex-2 gx-guarnic-gap-2">
                <CircleButton
                  onClick={() => toggleSlidePanel(false)}
                  type="dark"
                  iconName="close"
                />
              </div>
            </div>
          </div>
          <div className={styles.slideBody}>
            <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.LIST}>
              <div className="gx-mt-2 gx-mb-3 gx-w-100">
                <div className="gx-flex-row gx-align-items-center gx-justify-content-between">
                  <span className={styles.boardTitle}>
                    <IntlMessages id="dashboards.metrics.board.organization" />
                  </span>
                  <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CREATE}>
                    <CircleButton
                      onClick={() => onOpenBoardForm('organization')}
                      type="primary"
                      iconName="add"
                      iconSize={isMobile ? 'md' : 'sm'}
                    />
                  </ComponentBlocker>
                </div>

                <div className={styles.blockContainer}>
                  <SlideElement
                    data={boardsByOrg}
                    onChange={onBoardChange}
                    elementSelected={boardSelected}
                  />
                </div>
              </div>
              <hr className={styles.separator} />
            </ComponentBlocker>

            <div className="gx-mt-2 gx-mb-3 gx-w-100">
              <div className="gx-flex-row gx-align-items-center gx-justify-content-between">
                <span className={styles.boardTitle}>
                  <IntlMessages id="dashboards.metrics.board.division" />
                </span>
                <ComponentBlocker
                  allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.DASHBOARDS.CREATE}
                >
                  <CircleButton
                    onClick={() => onOpenBoardForm('division')}
                    type="primary"
                    iconName="add"
                    iconSize={isMobile ? 'md' : 'sm'}
                  />
                </ComponentBlocker>
              </div>
              <div className={styles.blockContainer}>
                <SlideElement
                  data={boardsByDiv}
                  onChange={onBoardChange}
                  elementSelected={boardSelected}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
