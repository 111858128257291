import React from 'react';
import { Avatar, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { userSignOut } from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import { getFullUrl } from 'packages/utils';
import { PATHS as USER_PATHS } from 'routes/user/constants.js';
import { getRouteToUserNotifications } from '../../packages/users/constants';

const UserInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();

  const userClaims = useSelector(({ user }) => user.access.data?.claims);
  const currentUser = useSelector(({ user }) => user.profile.data);

  const handleLogout = () => dispatch(userSignOut());

  const handleProfileRedirect = () => history.push(getFullUrl(USER_PATHS.PROFILE, url));

  const handleNotificationRedirect = () => {
    history.push(getFullUrl(getRouteToUserNotifications(currentUser.id), url));
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={handleProfileRedirect}>
        <IntlMessages id="sidebar.extensions.profile" />
      </li>
      <li onClick={handleNotificationRedirect}>
        <IntlMessages id="sidebar.extensions.notifications" />
      </li>
      <li onClick={handleLogout}>
        <IntlMessages id="sidebar.extensions.logout" />
      </li>
    </ul>
  );

  const nameInitials = name =>
    name
      .split(' ')
      .map(n => n[0])
      .splice(0, 2)
      .join('');

  const fullName = `${currentUser?.firstName} ${currentUser?.lastName}`;
  return (
    <Popover
      overlayClassName="gx-popover-horizontal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Avatar
        src={userClaims?.picture || currentUser.photoURL}
        className="gx-avatar gx-pointer"
        alt={fullName}
        style={{ width: 40, height: 40, backgroundColor: '#87d068' }}
      >
        {nameInitials(fullName)}
      </Avatar>
    </Popover>
  );
};

export default UserInfo;
