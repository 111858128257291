import React from 'react';
import PropTypes from 'prop-types';
import { Select, DatePicker, Radio, Col, Row, Grid } from 'antd';
import moment from 'moment';
import IntlMessages from 'util/IntlMessages';
import classnames from 'classnames';
import { KeyValueWidget } from 'components/KeyValueWidget';
import Title from 'components/BoxContainer/components/Title';
import SelectRefreshTimer from '../../../../../../components/SelectRefreshTimer';
import { LIST_TIMER_OPTIONS } from '../../constants';
import { filtersProptype } from '../../../../../utils/proptypes/charts';
import {
  RELATIVE_DATE_PER_DEFAULT,
  RELATIVE_OPTIONS,
  GROUP_BY_OPTIONS,
  RELATIVE_ABSOLUTE,
} from './constants';
import styles from './styles.module.less';

const { RangePicker } = DatePicker;

const { useBreakpoint } = Grid;

const ChartFilters = ({ handleFilters, onRefreshCharts, filters, pauseTime }) => {
  const parserDates = event => {
    const { value } = event.target;
    const { dateType } = RELATIVE_OPTIONS.find(e => e.value === value);

    return {
      isRelative: true,
      dateType,
      relativeAmount: value,
    };
  };
  const disabledDate = date => moment().isBefore(date);
  const {
    relative: { isRelative, relativeAmount },
    dateRange = [],
    groupBy,
  } = filters;

  const screens = useBreakpoint();
  const isMobile = screens.xs;

  const fromByDefault = moment().subtract(7, 'days');

  let dateRangeFormated = dateRange || [fromByDefault, moment()];
  if (dateRange?.length === 2) {
    dateRangeFormated = [moment(dateRange[0]), moment(dateRange[1])];
  }

  const inputSize = 'middle';
  return (
    <Row justify="space-between" gutter={[0, 0]}>
      {isMobile ? (
        <>
          <Col xs={24} lg={12} xl={4} xxl={4}>
            <Radio.Group
              className="gx-w-100"
              onChange={e =>
                handleFilters({
                  name: 'relative',
                  value: {
                    ...RELATIVE_DATE_PER_DEFAULT,
                    isRelative: e.target.value,
                  },
                })
              }
              defaultValue={isRelative}
              value={isRelative}
              size={inputSize}
            >
              {RELATIVE_ABSOLUTE.map(({ label, value }) => (
                <Radio.Button className="gx-w-50 gx-text-center" key={label} value={value}>
                  <span style={{ fontSize: '12px' }}>
                    <IntlMessages id={`dashboards.schedules.${label}`} />
                  </span>
                </Radio.Button>
              ))}
            </Radio.Group>
          </Col>
          <Col xs={24} lg={12} xl={8} xxl={7}>
            {filters.relative.isRelative ? (
              <Radio.Group
                className="gx-w-100"
                onChange={e => handleFilters({ name: 'relative', value: parserDates(e) })}
                defaultValue={relativeAmount}
                value={relativeAmount}
              >
                {RELATIVE_OPTIONS.map(e => (
                  <Radio.Button style={{ width: '16.6%' }} key={e.label} value={e.value}>
                    {e.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            ) : (
              <RangePicker
                size={inputSize}
                picker={filters.relative.dateType === 'day' ? 'date' : filters.relative.dateType}
                onChange={val => handleFilters({ name: 'dateRange', value: val })}
                disabledDate={disabledDate}
                defaultValue={dateRangeFormated}
                className={styles.unRelativeInput}
              />
            )}
          </Col>
        </>
      ) : (
        <Col xs={24} lg={11} xl={11} xxl={11}>
          <KeyValueWidget
            label={<Title.Filter value={<IntlMessages id="dashboards.chart.filters" />} />}
            value={
              <div className="gx-flex-row gx-guarnic-gap-1">
                <div className="gx-flex-1">
                  <Radio.Group
                    className="gx-w-100"
                    onChange={e =>
                      handleFilters({
                        name: 'relative',
                        value: {
                          ...RELATIVE_DATE_PER_DEFAULT,
                          isRelative: e.target.value,
                        },
                      })
                    }
                    defaultValue={isRelative}
                    value={isRelative}
                    size={inputSize}
                  >
                    {RELATIVE_ABSOLUTE.map(({ label, value }) => (
                      <Radio.Button className="gx-w-50" key={label} value={value}>
                        <span style={{ fontSize: '12px' }}>
                          <IntlMessages id={`dashboards.schedules.${label}`} />
                        </span>
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </div>
                <div className="gx-flex-1">
                  {filters.relative.isRelative ? (
                    <Radio.Group
                      className="gx-w-100"
                      onChange={e => handleFilters({ name: 'relative', value: parserDates(e) })}
                      defaultValue={relativeAmount}
                      value={relativeAmount}
                    >
                      {RELATIVE_OPTIONS.map(e => (
                        <Radio.Button style={{ width: '16.6%' }} key={e.label} value={e.value}>
                          {e.label}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  ) : (
                    <RangePicker
                      size={inputSize}
                      picker={
                        filters.relative.dateType === 'day' ? 'date' : filters.relative.dateType
                      }
                      onChange={val => handleFilters({ name: 'dateRange', value: val })}
                      disabledDate={disabledDate}
                      defaultValue={dateRangeFormated}
                      className={styles.unRelativeInput}
                    />
                  )}
                </div>
              </div>
            }
          />
        </Col>
      )}

      <Col xs={24} lg={12} xl={6} xxl={6}>
        <KeyValueWidget
          label={<Title.Filter value={<IntlMessages id="dashboards.chart.group.by" />} />}
          value={
            <Select
              style={{ width: '100%' }}
              onChange={val => handleFilters({ name: 'groupBy', value: val })}
              defaultValue={groupBy}
              value={groupBy}
              size={inputSize}
              className={classnames(styles.inputFilterMobile)}
            >
              {GROUP_BY_OPTIONS.map(item => (
                <Select.Option key={item}>
                  <IntlMessages id={`dateTypes.${item}`} values={{ amount: 1 }} />
                </Select.Option>
              ))}
            </Select>
          }
        />
      </Col>
      <Col xs={24} lg={12} xl={6} xxl={6}>
        <KeyValueWidget
          label={<Title.Filter value={<IntlMessages id="dashboard.chart.refresh.label" />} />}
          value={
            <SelectRefreshTimer
              onRefresh={e => onRefreshCharts(e)}
              addManualRefresh
              manualButtonPos={isMobile ? 'right' : 'left'}
              times={LIST_TIMER_OPTIONS}
              initValue={1}
              className={styles.refreshInput}
              size={isMobile && '100%'}
              forcePauseTimer={pauseTime}
            />
          }
        />
      </Col>
    </Row>
  );
};

ChartFilters.propTypes = {
  filters: filtersProptype.isRequired,
  handleFilters: PropTypes.func.isRequired,
  onRefreshCharts: PropTypes.func.isRequired,
  pauseTime: PropTypes.bool,
};

export default ChartFilters;
