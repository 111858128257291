import React, { useMemo } from 'react';

import { Select } from 'antd';
import classNames from 'classnames';

import EventName from 'components/TriggersList/component/EventName';
import styles from './styles.module.less';
import PropTypes from 'prop-types';

const FormSelect = ({ options, ...props }) => {
  const selectOptions = useMemo(
    () =>
      options?.map(form => ({
        label: <EventName eventIcon={form.icon} eventColor={form.color} eventName={form.name} />,
        value: form?.id,
        searchText: form?.name,
      })),
    [options],
  );

  return (
    <Select
      {...props}
      className={classNames(styles.selectForm, props.className)}
      optionFilterProp="searchText"
      optionLabelProp="label"
      options={selectOptions}
    />
  );
};

FormSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string,
      color: PropTypes.string,
    }),
  ).isRequired,
  className: PropTypes.string,
};

export default React.memo(FormSelect);
