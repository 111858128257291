import React, { useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Marker } from 'react-leaflet';
import Proptypes from 'prop-types';
import MemberAvatar from 'components/MemberList/MemberAvatar';
import { DivIcon } from 'leaflet';
import BoxContainer from 'components/BoxContainer';
import 'leaflet/dist/leaflet.css';

import Map from 'components/Maps/Map';
import { RecenterAutomatically } from 'components/Maps/RecenterAutomatically';
import FlyToMap from 'components/Maps/FlyToMap';
import { UserDetail } from 'components/Maps/UserDetail';
import { getInitialsName } from 'packages/utils';
import { Avatar } from 'antd';
import styles from './styles.module.less';

const UserLocationTracker = React.memo(({ users = [], currentUser = null, mapRef }) => {
  const userIds = users.map(item => item.id);

  const markers = useMemo(
    () =>
      users
        .map(record => {
          const userData = users.find(item => item.id === record.id);
          const userLocation = record?.positions;

          if (userData && userLocation) {
            return {
              id: userData.id,
              geoCode: userLocation,
              photoURL: userData.photoURL,
              displayName: userData.displayName,
              ...userData,
            };
          }
          return null;
        })
        .filter(item => item !== null && userIds.includes(item.id)), // Filter out null values and ensure user is in main view
    [users, userIds],
  );

  // Custom Icon
  const iconMarkup = (usr, userSelected) =>
    renderToStaticMarkup(
      <div className={userSelected ? styles.userSelected : ''}>
        {usr.photoURL ? (
          <MemberAvatar user={usr} size={40} />
        ) : (
          <Avatar
            size={24}
            icon={<span>{getInitialsName(`${usr?.firstName} ${usr?.lastName}`)}</span>}
            className={styles.locationUserAvatar}
          />
        )}
      </div>,
    );
  const customIcon = usr => {
    // To check if user is selected
    const hasUserSelected = usr.id === currentUser?.id;
    return new DivIcon({
      html: iconMarkup(usr, hasUserSelected),
    });
  };

  const userFocused = React.useMemo(() => {
    const userFounded = users.find(item => item?.id === currentUser?.id);

    if (!userFounded) return null;

    return userFounded?.positions;
  }, [currentUser?.id, users]);

  return (
    <BoxContainer>
      <Map mapRef={mapRef} gestureHandling>
        {markers.length > 0 &&
          markers.map(marker => (
            <Marker key={marker.id} position={marker.geoCode} icon={customIcon(marker)}>
              <UserDetail user={marker} />
            </Marker>
          ))}
        <RecenterAutomatically geoCode={markers[0]?.geoCode} />
        <FlyToMap position={userFocused} />
      </Map>
    </BoxContainer>
  );
});

UserLocationTracker.propTypes = {
  currentUser: Proptypes.object,
  users: Proptypes.array,
  mapRef: Proptypes.object,
};

export default UserLocationTracker;
