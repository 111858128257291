import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Modal, notification } from 'antd';

import { db } from 'firebase/firebase';
import { get, set } from 'packages/utils/storage';
import { ALLOWED_ROLES, useFirestoreRealtimeBatched } from 'packages/utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { collection, orderBy, query } from 'firebase/firestore';
import { deleteBoard } from 'packages/dashboard/screens/EventsDashboard/formHandlers';
import useFullUrl from 'packages/utils/hooks/useFullUrl';
import { useLocation } from 'react-router-dom';
import useGetDivisionsAllowed from 'packages/utils/hooks/useGetDivisionsAllowed';

export const SlidePanelContext = createContext();
export const BOARD_ACTIONS = {
  create: 'create',
  edit: 'edit',
};
const DEFAULT_BOARD_SELECTED = {
  id: null,
  name: null,
  divId: null,
};
let executed = false;

export const SlidePanelProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [openBoardForm, setOpenBoardForm] = useState(false);
  const boardActionRef = useRef('');
  const boardTypeSelected = useRef('');
  const { goToPath } = useFullUrl();
  const boardStorage = get('metricsBoard');
  const intl = useIntl();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const boardId = queryParams.get('boardId');

  const [boardSelected, setBoardSelected] = useState(() =>
    boardStorage?.boardSelected?.id ? boardStorage.boardSelected : DEFAULT_BOARD_SELECTED,
  );
  const orgId = useSelector(state => state.organizations.organization.id);

  const { allowedSelectedDivs } = useGetDivisionsAllowed(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.DASHBOARDS.LIST,
  );

  const boardsRef = [
    query(collection(db, 'organizations', orgId, 'dashboards'), orderBy('createdAt')),
  ];
  const { data: boardsByOrg, loading: boardsLoading } = useFirestoreRealtimeBatched(boardsRef, [
    orgId,
  ]);

  const divisionBoardsRef = allowedSelectedDivs.map(divId =>
    query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'dashboards'),
      orderBy('createdAt'),
    ),
  );
  const { data: boardsByDiv, loading: boardsByDivLoading } = useFirestoreRealtimeBatched(
    divisionBoardsRef,
    [orgId, allowedSelectedDivs.length],
  );

  const boardSelectedMemo = useMemo(() => {
    let output = boardSelected;
    if (boardSelected.divId) {
      output = boardsByDiv.find(board => board.id === boardSelected.id);
    } else {
      output = boardsByOrg.find(board => board.id === boardSelected.id);
    }

    return {
      id: output?.id,
      name: output?.name,
      divId: output?.divId ?? null,
      queryParams: output?.queryParams,
    };
  }, [boardSelected, boardsByDiv, boardsByOrg]);

  const mainLoading = useMemo(
    () => boardsLoading || boardsByDivLoading,
    [boardsLoading, boardsByDivLoading],
  );

  const onBoardChange = values => {
    setBoardSelected(prev => {
      if (prev.id === values.id) return DEFAULT_BOARD_SELECTED;

      const currentSelected = {
        id: values?.id,
        name: values?.name,
        divId: values?.divId ?? null,
      };

      set('metricsBoard', {
        boardSelected: currentSelected,
      });

      return currentSelected;
    });
  };

  const onOpenBoardForm = boardType => {
    setOpenBoardForm(true);
    boardActionRef.current = BOARD_ACTIONS.create;
    boardTypeSelected.current = boardType;
  };
  const onOpenBoardFormEdit = () => {
    setOpenBoardForm(true);
    boardActionRef.current = BOARD_ACTIONS.edit;
  };

  const onCloseBoardForm = () => {
    setOpenBoardForm(false);
    boardActionRef.current = '';
    boardTypeSelected.current = '';
  };

  const toggleSlidePanel = () => {
    setIsOpen(!isOpen);
  };

  const onDeleteBoard = () => {
    Modal.confirm({
      cancelText: intl.formatMessage({ id: 'button.no' }),
      icon: <ExclamationCircleOutlined />,
      okText: intl.formatMessage({ id: 'button.yes' }),
      okType: 'danger',
      onOk() {
        deleteBoard({ orgId, boardId: boardSelected.id, divId: boardSelected.divId })
          .then(() => {
            notification.success({
              message: intl.formatMessage({ id: 'general.save.successful.message' }),
            });
          })
          .then(() => {
            setBoardSelected(DEFAULT_BOARD_SELECTED);
          });
      },
      title: intl.formatMessage(
        { id: 'dashboards.confirm.delete.board' },
        { value: boardSelected.name },
      ),
    });
  };

  // NOTE: This useEffect is used to set the board selected when the user access to the page with a boardId in the query params
  useEffect(() => {
    if (boardId && !executed) {
      const allBoards = [...boardsByOrg, ...boardsByDiv];
      const board = allBoards.find(b => b.id === boardId);
      if (board) {
        setBoardSelected({
          id: board.id,
          name: board.name,
          divId: board.divId ?? null,
        });
        executed = true;
      }
    }
  }, [boardId, boardsByDiv, boardsByOrg]);

  // NOTE: This useEffect is used to update the query params when the board selected changes
  useEffect(() => {
    if (boardStorage?.boardSelected?.id) {
      const queryParamsBuilded = new URLSearchParams({
        boardId: boardStorage?.boardSelected?.id,
      }).toString();
      window.history.replaceState(null, '', `${window.location.pathname}?${queryParamsBuilded}`);
    }
  }, [boardSelected, boardStorage?.boardSelected?.id, goToPath]);

  return (
    <SlidePanelContext.Provider
      value={{
        isOpen,
        toggleSlidePanel,
        openBoardForm,
        onCloseBoardForm,
        onOpenBoardForm,
        onOpenBoardFormEdit,
        boardsLoading,
        boardsByOrg,
        boardFormAction: boardActionRef.current,
        boardIsEdit: boardActionRef.current === BOARD_ACTIONS.edit,
        onBoardChange,
        boardSelected: boardSelectedMemo,
        mainLoading,
        boardsByDiv,
        onDeleteBoard,
        isBoardTypeDiv: boardTypeSelected.current === 'division',
      }}
    >
      {children}
    </SlidePanelContext.Provider>
  );
};

SlidePanelProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
