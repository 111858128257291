export const ACTIONS = {
  UTI_FRB_FETCH: 'UTI_FRB_FETCH',
  UTI_FRB_FETCH_ERROR: 'UTI_FRB_FETCH_ERROR',
  UTI_FRB_FETCH_BATCH_SUCCESS: 'UTI_FRB_FETCH_BATCH_SUCCESS',
  UTI_FRB_RESET: 'UTI_FRB_RESET',
};

export const realtimeFetch = batchesAmount => ({
  payload: { batchesAmount },
  type: ACTIONS.UTI_FRB_FETCH,
});

export const realtimeFetchError = error => ({
  payload: error,
  type: ACTIONS.UTI_FRB_FETCH_ERROR,
});

export const realtimeFetchBatchSuccess = (
  batchId,
  lastDocument,
  data,
  next,
  documentIdsDeleted,
) => ({
  payload: { batchId, lastDocument, data, next, documentIdsDeleted },
  type: ACTIONS.UTI_FRB_FETCH_BATCH_SUCCESS,
});

export const realtimeReset = () => ({
  type: ACTIONS.UTI_FRB_RESET,
});
