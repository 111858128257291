import React from 'react';
import styles from './styles.module.less';
export default function OperondaLogo() {
  return (
    <div className="gx-flex-row gx-guarnic-gap-1 gx-pt-2 gx-pb-2">
      <img width={40} height={25} src="/logo_operonda.png" />
      <span className={styles.operondaText}>Operonda</span>
    </div>
  );
}
