import React from 'react';

import { Tag } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.less';

const Tags = ({ tags = [], fullSize = false }) => (
  <div>
    <div className="gx-flex-row">
      {tags?.length > 0 &&
        tags.map(tag => (
          <Tag key={tag} className={classnames(styles.item, { [styles.ellipsis]: !fullSize })}>
            {tag}
          </Tag>
        ))}
    </div>
  </div>
);

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  fullSize: PropTypes.bool,
};

export default React.memo(Tags);
