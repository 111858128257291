import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { USER_ROLES, hasAnyAllowedRole } from 'packages/utils';

import styles from './styles.module.less';

const MenuGroup = ({ name, selected = false, allowedRoles = [], children, icon }) => {
  const [showOptions, setShowOptions] = useState(false);
  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  const hasAccess = hasAnyAllowedRole(allowedRoles, userOrgAccess, userOrgDivisions);

  const handleClick = () => {
    if (selected) return;
    setShowOptions(prev => !prev);
  };

  return hasAccess ? (
    <button
      type="button"
      className={classnames(styles.button, selected && styles.selected)}
      onClick={handleClick}
    >
      <div className={styles.name}>
        {icon && icon}
        <span>{name}</span>
      </div>
      {(selected || showOptions) && <div className="gx-flex-column gx-ml-1">{children}</div>}
    </button>
  ) : (
    <> </>
  );
};

MenuGroup.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  allowedRoles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_ROLES))),
  icon: PropTypes.node,
};

export default MenuGroup;
