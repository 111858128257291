import React from 'react';

import { Button } from 'antd';

import links from '@constants/links';
import IntlMessages from 'util/IntlMessages';

import styles from './styles.module.less';

export default function LocationAlertSuccessful() {
  const onOk = () => {
    window.location.href = links.operonda;
  };

  return (
    <div className={styles.locationAlertSuccessful}>
      <div className="gx-h-100 gx-flex-row gx-justify-content-center gx-align-content-center">
        <div className="gx-flex-column gx-align-items-center">
          <div className="gx-mb-3">
            <span className="material-symbols-outlined gx-fs-80 gx-text-blue-primary">
              check_circle
            </span>
          </div>
          <div className="gx-flex-row gx-justify-content-center gx-align-content-center gx-fs-2xl">
            <p>
              <IntlMessages id="location.alert.message" />
            </p>
            <p className="gx-fs-md">
              <IntlMessages id="location.alert.message.thanks" />
            </p>
          </div>
        </div>
      </div>

      <Button onClick={onOk} type="primary">
        <IntlMessages id="button.ok" />
      </Button>
    </div>
  );
}
