import { useRouteMatch, useHistory } from 'react-router-dom';

const useFullUrl = () => {
  const { url: currentUrl } = useRouteMatch();
  const history = useHistory();

  const divider = '/-';
  const root = currentUrl.split(divider)[0];

  const goToPath = pathToLoad => {
    const fullUrl = `${root}${divider}/${pathToLoad}`;

    return history.push(fullUrl);
  };

  return {
    goToPath,
  };
};

export default useFullUrl;
