import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Pagination, Spin, Table } from 'antd';
import PropTypes from 'prop-types';

import { filtersProptype } from 'packages/utils/proptypes/charts';
import { tablePropType } from 'packages/utils/proptypes/dashboard/tableCard';
import useCloudFunction from 'packages/utils/hooks/clouds/useCloudFunction';
import { auth } from 'firebase/firebase';
import { useSelector } from 'react-redux';
import { getRange } from 'packages/dashboard/utils';
import moment from 'moment';
import { ALLOWED_ROLES, useIsMobile } from 'packages/utils';
import { isEqual } from 'lodash';
import EventName from 'components/TriggersList/component/EventName';
import { SlidePanelContext } from 'packages/dashboard/components/SlidePanel/SlidePanelContext';
import { get } from 'packages/utils/sessionStorage';
import DashboardCard from '../DashboardCard';
import { COMPONENTS_OF_METRICS, DATE_FROM_DEFAULT } from '../../constants';
import {
  buildDataSource,
  buildDynamicColumns,
  buildTableParams,
  PAGE_SIZE_BY_DEFAULT,
  PAGE_SIZE_BY_DEFAULT_MOBILE,
} from './constants';
import styles from './styles.module.less';

/**
 * TableCard component displays a table with data fetched from a cloud function.
 *
 * @param {Object} props - The properties object.
 * @param {Object} props.table - The table configuration object.
 * @param {boolean} props.refresh - Flag to refresh the table data.
 * @param {Object} props.filters - The filters applied to the table data.
 * @param {Function} props.onEdit - Callback function to handle edit action.
 * @param {Function} props.onDelete - Callback function to handle delete action.
 * @param {Object} props.elementsRef - The reference object to calculate the height of the table.
 */

const TableCard = React.memo(
  ({ table, refresh, filters, onEdit, onDelete, elementsRef, eventTypes }) => {
    const { isMobile } = useIsMobile();
    const { boardSelected } = useContext(SlidePanelContext);
    const sortFieldsStoraged = get('metricsTable');

    const [currentPage, setCurrentPage] = useState(1);
    const [lastPosition, setLastPosition] = useState(0);
    const [pageSize, setPageSize] = useState(PAGE_SIZE_BY_DEFAULT);

    const [sortFieldsByTable, setSortFieldsByTable] = useState(() => sortFieldsStoraged ?? {});

    const orgId = useSelector(state => state.organizations.organization.id);
    const selectedDivs = useSelector(({ divisions }) => divisions.selector.ids || []);
    const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

    const sortFieldsMemo = useMemo(
      () => sortFieldsByTable[table?.id]?.sortFields || [],
      [sortFieldsByTable, table?.id],
    );

    const sortFieldsChanged = sortFieldsMemo?.length > 0 ? sortFieldsMemo : true; // NOTE: This is a workaround to avoid the first render with empty sortFields

    const { queryParams, title, id } = table;
    const { columns = [], formId, triggerIds = [] } = queryParams || {};

    const {
      dateRange,
      relative: { dateType, relativeAmount, isRelative },
      groupBy,
    } = filters;

    let dateRangeFormated = dateRange || [DATE_FROM_DEFAULT, moment()];
    if (dateRange?.length === 2) {
      dateRangeFormated = [moment(dateRange[0]), moment(dateRange[1])];
    }
    const tableCardForm = eventTypes?.find(
      ({ id: eventTypeId }) => eventTypeId === table?.queryParams?.formId,
    );

    const dashboardParams = buildTableParams({
      formId,
      uid: auth.currentUser.uid,
      orgId,
      range: getRange(dateRangeFormated, dateType, isRelative, relativeAmount),
      interval: groupBy,
      divisionIds: boardSelected?.divId ? [boardSelected?.divId] : selectedDivs,
      triggerIds,
      lastPosition,
      pageSize: isMobile ? PAGE_SIZE_BY_DEFAULT_MOBILE : pageSize,
      sortFields: sortFieldsMemo,
    });
    const { data, loading } = useCloudFunction({
      params: dashboardParams,
      dependencies: [
        refresh,
        formId,
        dateRange,
        groupBy,
        selectedDivs,
        filters.relative,
        filters.dateRange,
        filters.groupBy,
        currentPage,
        pageSize,
        sortFieldsChanged,
      ],
      functionName: 'functions-tableDashboard-tableDashboard',
    });

    const hits = useMemo(() => data?.hits?.hits || [], [data]);
    const totalEvents = data?.hits?.total?.value || 0;

    // NOTE: We are gonna take the division according formId
    const boardDivision = userOrgDivisions[tableCardForm?.divId || ''] || {};

    const dataBuilded = buildDataSource({ records: data, division: boardDivision });

    const cols = buildDynamicColumns({
      columns,
      hits,
      table: { ...table, orgId },
      board: boardSelected,
      onSortFieldsChange: setSortFieldsByTable,
      sortFields: sortFieldsMemo,
    });

    const handlePageChange = (page, pSize) => {
      setCurrentPage(page);
      setPageSize(pSize);
      const newPosition = (page - 1) * pSize;

      setLastPosition(newPosition);
    };

    // NOTE: This is a workaround to calculate the height of the table, this only works in mobile
    useEffect(() => {
      const heightByTable = dataBuilded.length >= 5 ? 4.3 : dataBuilded.length;

      // eslint-disable-next-line no-param-reassign
      elementsRef.current = {
        ...elementsRef.current,
        [id]: heightByTable,
      };
    }, [dataBuilded, elementsRef, id]);

    return (
      <DashboardCard
        title={title}
        subTitle={
          <EventName
            eventColor={tableCardForm?.color}
            formName={`${tableCardForm?.name} (${totalEvents})`}
            eventIcon={tableCardForm?.icon}
          />
        }
        actionButtons={[
          {
            iconName: 'edit',
            action: () => onEdit(id, COMPONENTS_OF_METRICS.TABLE),
            type: 'secondary',
            iconSize: 'sm',
            allowedRole: [
              ...ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.UPDATE,
              ...ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.DASHBOARDS.CHARTS.UPDATE,
            ],
          },
          // TODO: Implement after
          // {
          //   iconName: 'download',
          //   action: () => {},
          //   type: 'secondary',
          //   iconSize: 'sm',
          // },
          {
            iconName: 'delete',
            action: onDelete,
            type: 'danger',
            iconSize: 'sm',
            allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.DELETE,
          },
        ]}
        className={styles.tableCard}
      >
        <Spin spinning={loading}>
          <Table dataSource={dataBuilded} columns={cols} pagination={false} />
          <div
            className={`gx-flex-row gx-justify-content-center ${
              isMobile ? 'gx-mt-1' : 'gx-mt-3'
            } gx-mb-2 cancelDrag`}
          >
            <Pagination
              current={currentPage}
              onChange={handlePageChange}
              pageSize={pageSize}
              defaultPageSize={5}
              total={totalEvents}
              size={isMobile && 'small'}
            />
          </div>
        </Spin>
      </DashboardCard>
    );
  },
  (prev, next) =>
    prev.table.id === next.table.id &&
    isEqual(prev?.table?.queryParams?.sortFields, next?.table?.queryParams?.sortFields) &&
    isEqual(prev.filters, next.filters) &&
    isEqual(prev.eventTypes, next.eventTypes),
);

TableCard.propTypes = {
  table: tablePropType.isRequired,
  refresh: PropTypes.bool.isRequired,
  filters: filtersProptype.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  elementsRef: PropTypes.object.isRequired,
  eventTypes: PropTypes.array,
};

export default TableCard;
