import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.less';
import { KeyValueWidget } from 'components/KeyValueWidget';
import moment from 'moment';
import IntlMessages from 'util/IntlMessages';
import ConnectionStatus from 'components/Widget/ConnectionStatus';
import BatteryStatus from 'components/Widget/BatteryStatus';
import LastConnection from 'components/Widget/LastConnection';
import { useIsMobile } from 'packages/utils';
import { Popup } from 'react-leaflet';

export const UserDetail = ({ user }) => {
  const { isMobile } = useIsMobile();
  const phoneDetails = {
    altitude: `${user?.altitude} m`,
    accuracy: `${user?.accuracy} m`,
    speed: `${user?.speed} km/h`,
    connected: user?.connected,
    battery: user?.battery,
    lastLogin: user?.lastLogin ?? null, //TODO: Missing
    time: user?.time ? moment(user?.time).format('h:mm') : '',
  };

  return (
    <Popup className={styles.userDetailPopup}>
      <div className={styles.userDetailMain}>
        <div className={styles.blockA}>
          <KeyValueWidget label={user?.displayName} />
          <KeyValueWidget labelClass={styles.popupUserTime} label={phoneDetails.time} />
        </div>
        <div className={styles.blockB}>
          <KeyValueWidget
            oneLine
            label={<IntlMessages id="common.altitude" />}
            value={phoneDetails.altitude}
          />
          <KeyValueWidget
            oneLine
            label={<IntlMessages id="common.accuracy" />}
            value={phoneDetails.accuracy}
          />
          <KeyValueWidget
            oneLine
            label={<IntlMessages id="common.speed" />}
            value={phoneDetails.speed}
          />
        </div>
        <div className={styles.blockC}>
          <div className="gx-flex-row">
            <ConnectionStatus
              className={!isMobile ? 'gx-pr-2' : 'gx-guarnic-pb-1'}
              connected={phoneDetails.connected}
            />
            <BatteryStatus
              className={!isMobile ? 'gx-pr-2' : 'gx-guarnic-pb-1'}
              batery={phoneDetails.battery}
            />
            {phoneDetails.lastLogin && <LastConnection lastLogin={phoneDetails.lastLogin} />}
          </div>
        </div>
      </div>
    </Popup>
  );
};

UserDetail.propTypes = {
  user: PropTypes.any, //TODO: defined when it is ready.
};
