export const STATUS_PER_DEFAULT = null;
export const USER_DISPLAY_PER_DEFAULT = 5;

export const INIT_FILTERS = {
  text: '',
  status: STATUS_PER_DEFAULT, // NOTE: "actives" or "inactive"
  userPerDisplay: USER_DISPLAY_PER_DEFAULT,
};

export const factoryActiveUsers = ({ users = [], userLocations = [], userFocused = {} }) =>
  users.map(
    ({
      id,
      photoURL = '',
      divisions: divisionsByUser,
      firstName = '',
      lastName = '',
      metrics: { signedIn = false } = {},
    }) => {
      const userLoc = userLocations.find(usr => usr?.[id]);
      const device = userLoc?.[id]?.device?.DEVICE_INFO;
      const positions = userLoc?.[id]?.positions?.UNIQUE_POSITION;
      const isSelected = Boolean(userFocused?.id === id);

      return {
        key: id,
        id,
        firstName,
        lastName,
        displayName: `${firstName} ${lastName}`,
        schedule: null, /// TODO: create formula
        event: null, /// TODO: create formula,
        onTrack: null, // TODO: create formula,
        lastLogin: null, // TODO: create formula,
        connected: signedIn || false,
        battery: device?.battery ?? 0,
        time: positions?.t ?? null,
        speed: positions?.s ?? 0,
        accuracy: positions?.ac ?? 0,
        altitude: positions?.a ?? 0,
        positions: positions?.lg && positions?.l ? [positions.l, positions.lg] : null,
        divisions: divisionsByUser,
        photoURL,
        isSelected,
      };
    },
  );
