import React, { useCallback, useMemo, useState } from 'react';
import { Switch, Popover, Divider } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile } from 'packages/utils';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { doc, serverTimestamp, writeBatch } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import { errorNotification } from 'appRedux/actions';
import { InfoCard } from 'components/InfoCard';
import styles from './styles.module.less';
import { scheduleProptype } from '../../../utils/proptypes/schedule';
import { SUBSCRIPTIONS_PAYLOAD, TYPES_PATH } from '../../screens/Notifications/constants';
import EventType from './EventType';
import useGetSubscriberByNotification from '../../../utils/hooks/useGetSubscriberByNotification';
import { userProptype } from '../../../utils/proptypes/user';

const ScheduleCard = React.memo(({ data, currentUser }) => {
  const dispatch = useDispatch();
  const orgId = useSelector(({ organizations }) => organizations.organization.id);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const { isMobile } = useIsMobile();
  const [totalEventTypes, setTotalEventTypes] = useState([]);

  const [batchLoading, setBatchLoading] = useState(false);

  const { name: scheduleName, divId, id, triggers = [] } = data;
  const divisionName = userOrgDivisions[divId]?.name;
  const allEventsSelected = totalEventTypes.length === triggers.length;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const triggerIds = useMemo(() => triggers.map(item => item.id), [JSON.stringify(triggers)]);

  const eventTypeNotificationBody = useMemo(
    () => ({
      orgId,
      type: TYPES_PATH.event_created,
      userId: currentUser?.id,
      divisionId: divId,
    }),
    [orgId, currentUser?.id, divId],
  );

  const scheduleEndNotificationBody = {
    orgId,
    type: TYPES_PATH.schedule_end,
    userId: currentUser?.id,
    entityId: id,
    divisionId: divId,
  };
  const { hasSubscriber, handleActionReport, fetching } = useGetSubscriberByNotification({
    user: currentUser,
    body: scheduleEndNotificationBody,
  });

  const receiveReportStatus = useMemo(
    () => allEventsSelected && hasSubscriber,
    [allEventsSelected, hasSubscriber],
  );

  const activeAllEvents = useCallback(
    async (pAction = '') => {
      setBatchLoading(true);
      const batch = writeBatch(db);
      const action = allEventsSelected ? 'delete' : 'set';
      const process = pAction || action;

      triggers.forEach(({ id: triggerId }) => {
        // Only uncheked when inserting
        if (process === 'set') {
          const docExist = totalEventTypes.includes(triggerId);
          if (docExist) return;
        }
        const docRef = doc(
          db,
          'organizations',
          orgId,
          'notification_subscriptions',
          TYPES_PATH.event_created,
          'entities',
          triggerId,
          'subscribers',
          currentUser?.id,
        );

        batch[process](docRef, {
          divId,
          id: docRef.id,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          subscriptions: SUBSCRIPTIONS_PAYLOAD,
          user: currentUser,
        });
      });

      try {
        await batch.commit();
        setBatchLoading(false);

        if (allEventsSelected && process === 'delete') {
          setTotalEventTypes([]);
        } else {
          setTotalEventTypes(triggerIds);
        }
      } catch (e) {
        setBatchLoading(false);
        dispatch(errorNotification(e.message || e));
      }
    },
    [allEventsSelected, currentUser, dispatch, divId, orgId, totalEventTypes, triggerIds, triggers],
  );

  const onReceiveReport = async () => {
    const deleteComplete = hasSubscriber && allEventsSelected;
    const promises = [];
    if (deleteComplete) {
      promises.push(handleActionReport('deleteDoc'), activeAllEvents('delete'));
    } else {
      if (!hasSubscriber) {
        const actionReportToExecute = hasSubscriber ? 'deleteDoc' : 'setDoc';
        promises.push(handleActionReport(actionReportToExecute));
      }

      if (!allEventsSelected) {
        const activeAllToExecute = allEventsSelected ? 'delete' : 'set';
        promises.push(activeAllEvents(activeAllToExecute));
      }
    }
    try {
      await Promise.all(promises);
    } catch (e) {
      dispatch(errorNotification(e.message || e));
    }
  };

  return (
    <InfoCard
      extraRightContent={
        <div className="gx-flex-row gx-justify-content-end">
          <span className={styles.activeAll}>
            <IntlMessages id="user.notification.receiveReport" />
          </span>
          <Switch
            checked={receiveReportStatus}
            defaultChecked={receiveReportStatus}
            onChange={onReceiveReport}
            loading={fetching}
          />
        </div>
      }
      title={
        <div className="gx-flex-row gx-w-100 gx-justify-content-between">
          <div className={styles.infoH}>
            <div className={styles.scheduleName}>{scheduleName}</div>
            <span className={styles.eventName}>{divisionName}</span>
          </div>
        </div>
      }
    >
      <div className="gx-pb-4">
        <div className={styles.reportEndSwitch}>
          <IntlMessages id="user.notification.element.action.completion.report" />
          <Switch
            checked={hasSubscriber}
            onChange={() => handleActionReport()}
            loading={fetching}
          />
        </div>
      </div>

      <div className={styles.scheduleCardBody}>
        <div className={styles.sheduleCardEventTypeTitle}>
          <span className={styles.eventTypeTitle}>
            <IntlMessages id="user.notifications.eventType.title" />
          </span>
          {/* ACTIVE ALL EVENTS */}
          <div className="gx-flex-row">
            <span className={styles.activeAll}>
              <IntlMessages id="user.notification.activeAll" />
            </span>
            <div className="gx-flex-row">
              <Switch
                defaultChecked={allEventsSelected}
                checked={allEventsSelected}
                onChange={() => activeAllEvents()}
                loading={batchLoading}
              />
              {!isMobile && (
                <Popover
                  placement="topLeft"
                  content={
                    <div style={{ width: '200px' }}>
                      <IntlMessages id="user.notification.info" />
                    </div>
                  }
                  trigger="hover"
                >
                  <InfoCircleTwoTone className="gx-pl-2" />
                </Popover>
              )}
            </div>
          </div>
        </div>
        <Divider className="gx-pt-3" />
        {/* EVENTS TYPES */}
        <div className={styles.eventTypesC}>
          {triggers.map(tr => (
            <React.Fragment key={tr.id}>
              <EventType
                trigger={tr}
                user={currentUser}
                notificationBody={eventTypeNotificationBody}
                onTotalEventTypes={setTotalEventTypes}
                loading={batchLoading}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </InfoCard>
  );
});

ScheduleCard.propTypes = {
  data: scheduleProptype.isRequired,
  currentUser: userProptype.isRequired,
};

export default ScheduleCard;
