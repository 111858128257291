import { ALLOWED_ROLES } from 'packages/utils';

import { PATHS } from './constants';
import LocationAlerts from './screens/LocationAlerts';
import LocationAlertDetails from './screens/LocationAlertDetails';

export default [
  {
    component: LocationAlerts,
    path: PATHS.LOCATION_ALERTS,
    exact: true,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.LOCATION_ALERTS.LIST,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: LocationAlertDetails,
    path: PATHS.LOCATION_ALERT_DETAILS,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.LOCATION_ALERTS.LIST,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
];
