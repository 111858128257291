import React from 'react';
import classname from 'classnames';
import { Card, Col, Row, Spin } from 'antd';
import PropTypes from 'prop-types';

import IntlMessages from 'util/IntlMessages';
import { getUserTimeZoneDate, useFirestoreQuery } from 'packages/utils';
import { db } from 'firebase/firebase';
import { collection } from 'firebase/firestore';

import styles from './styles.module.less';
import TriggerProgressItem from '../../packages/dashboard/screens/ActiveSchedulesDashboard/components/TriggerProgressItem';
import ExecutionCounter from '../ExecutionCounter';
import { LOC_TYPES } from '../../packages/locations/constants';
import { getCompletitionData } from '../../packages/utils/functions/triggers';
import DisplayDuration from '../DisplayDuration';
import ExecutionStatus from '../ExecutionStatus';
import { eventTypeSummaryPropTypes } from '../../packages/utils/proptypes/eventTypes';
import { useSelector } from 'react-redux';
import { KeyValueWidget } from 'components/KeyValueWidget';
import Members from 'components/MemberList/Members';

const ExecutionItem = ({ execution = {} }) => {
  const profile = useSelector(({ user }) => user.profile);

  const {
    organization: { id: orgId },
    division: { id: divId },
    id: execId,
    startAt,
    endAt,
    schedule: { duration, schedule },
    viewed = [],
    delivered = [],
    deliveryState = {},
  } = execution;

  const { data: triggers = [], loading: triggersLoading } = useFirestoreQuery(
    collection(db, 'organizations', orgId, 'divisions', divId, 'executions', execId, 'triggers'),
    [orgId, divId, execId],
  );

  const completitionData = getCompletitionData(triggers);

  const startTz = getUserTimeZoneDate(
    startAt.toMillis(),
    schedule.timeZone,
    profile.data?.timeZone,
  ).jsDate;
  const endTz = getUserTimeZoneDate(
    endAt.toMillis(),
    schedule.timeZone,
    profile.data?.timeZone,
  ).jsDate;

  const getStatus = percentageCompleted => {
    if (percentageCompleted < 100) {
      return (
        <div className={classname(styles.status, styles.incomplete)}>
          <div className={styles.progressBar} style={{ width: `${percentageCompleted}%` }} />
          <span>{percentageCompleted.toFixed()}%</span>
        </div>
      );
    }
    return (
      <div className={classname(styles.status, styles.complete)}>
        <span>{percentageCompleted.toFixed()}%</span>
      </div>
    );
  };

  return (
    <Card
      className={styles.progressItemContainer}
      size="small"
      bodyStyle={{ marginLeft: '12px' }}
      actions={[getStatus(completitionData?.finishedPercentage || 0)]}
    >
      <Row gutter={[0, 8]}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={8}>
          <KeyValueWidget
            label={<IntlMessages id="general.schedule" />}
            value={execution?.schedule?.name}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={8}>
          <KeyValueWidget
            label={<IntlMessages id="general.division" />}
            value={execution.division.name}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={8}>
          <ExecutionCounter execution={execution} triggers={triggers} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={8}>
          <KeyValueWidget
            label={<IntlMessages id="execution.startDate.label" />}
            value={
              <IntlMessages
                id="general.nextOn"
                values={{
                  date: startTz,
                }}
              />
            }
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={8}>
          <KeyValueWidget
            label={<IntlMessages id="execution.endDate.label" />}
            value={
              <IntlMessages
                id="general.nextOn"
                values={{
                  date: endTz,
                }}
              />
            }
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={8}>
          <KeyValueWidget
            label={<IntlMessages id="schedule.form.duration.label" />}
            value={<DisplayDuration minutes={duration} />}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={8}>
          <KeyValueWidget
            label={<IntlMessages id="execution.status.label" />}
            value={<ExecutionStatus execution={execution} />}
          />
        </Col>
        <Col className={styles.memberListContent} xs={24} sm={12} md={12} xl={12} xxl={8}>
          <Members
            users={execution?.schedule?.members?.users}
            groups={execution?.schedule?.members?.groups}
            flatDeliveryState={{
              viewed,
              delivered,
              deliveryState,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <KeyValueWidget
            label={<IntlMessages id="dashboards.schedules.progressItem.triggers.label" />}
            value={
              <div className={styles.triggerScroll}>
                {triggersLoading && <Spin />}
                {triggers.map(t => (
                  <TriggerProgressItem key={t.id} trigger={t} />
                ))}
              </div>
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

ExecutionItem.propTypes = {
  execution: PropTypes.shape({
    createdAt: PropTypes.object.isRequired,
    division: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    endAt: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    organization: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    schedule: PropTypes.shape({
      createdAt: PropTypes.object.isRequired,
      divId: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,

      members: PropTypes.shape({
        groups: PropTypes.arrayOf(
          PropTypes.shape({
            divId: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            avatarURL: PropTypes.string,
            users: PropTypes.arrayOf(
              PropTypes.shape({
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
                photoURL: PropTypes.string,
                displayName: PropTypes.string.isRequired,
                id: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
              }),
            ),
          }),
        ).isRequired,
        users: PropTypes.arrayOf(
          PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            photoURL: PropTypes.string,
            displayName: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
          }),
        ),
      }).isRequired,
      name: PropTypes.string.isRequired,
      reminders: PropTypes.arrayOf(PropTypes.number).isRequired,
      triggers: PropTypes.arrayOf(
        PropTypes.shape({
          avoidConsecutiveTriggering: PropTypes.bool.isRequired,
          cooldown: PropTypes.number.isRequired,
          description: PropTypes.string.isRequired,
          eventType: eventTypeSummaryPropTypes.isRequired,
          expectedExecutions: PropTypes.number.isRequired,
          id: PropTypes.string.isRequired,
          locations: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
              type: PropTypes.oneOf(Object.values(LOC_TYPES)).isRequired,

              token: PropTypes.string,
              distance: PropTypes.shape({
                outside: PropTypes.bool.isRequired,
                meters: PropTypes.number.isRequired,
              }),
              divId: PropTypes.string,
              position: PropTypes.shape({
                lat: PropTypes.number.isRequired,
                lng: PropTypes.number,
                lon: PropTypes.number,
              }),
            }),
          ),
          name: PropTypes.string.isRequired,
        }),
      ),
      jobs: PropTypes.shape({
        start: PropTypes.shape({
          cron: PropTypes.string.isRequired,
          execution: PropTypes.shape({
            group: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            uuid: PropTypes.string.isRequired,
            status: PropTypes.string,
            lastExecution: PropTypes.string,
            nextExecution: PropTypes.string,
            description: PropTypes.string,
          }),
        }).isRequired,
        end: PropTypes.shape({
          cron: PropTypes.string.isRequired,
          execution: PropTypes.shape({
            group: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            uuid: PropTypes.string.isRequired,
            status: PropTypes.string,
            lastExecution: PropTypes.string,
            nextExecution: PropTypes.string,
            description: PropTypes.string,
          }),
        }).isRequired,
        reminders: PropTypes.arrayOf(
          PropTypes.shape({
            cron: PropTypes.string.isRequired,
            execution: PropTypes.shape({
              group: PropTypes.string.isRequired,
              state: PropTypes.string.isRequired,
              uuid: PropTypes.string.isRequired,
              status: PropTypes.string,
              lastExecution: PropTypes.string,
              nextExecution: PropTypes.string,
              description: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
    startAt: PropTypes.object.isRequired,
    triggersIds: PropTypes.arrayOf(PropTypes.string),
    updatedAt: PropTypes.object.isRequired,
  }).isRequired,
};

export default ExecutionItem;
