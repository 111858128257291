import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col, DatePicker, Form, Input, Row, Select, Switch } from 'antd';

import { db } from 'firebase/firebase';
import { collection } from 'firebase/firestore';
import BoxContainer from 'components/BoxContainer';
import { useFirestoreQuery, useIsMobile } from 'packages/utils';
import Title from 'components/BoxContainer/components/Title';
import Poi from 'components/Location/Poi';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import FormSelect from 'components/Form/components/FormSelect';

/**
 * Component for creating a new trigger for a work template.
 *
 * @component
 * @param {string} divisionId - The ID of the division.
 * @param {object} form - The form object from antd Form.
 * @param {boolean} activeForm - Indicates whether the form is active or not.
 * @returns {JSX.Element} - The JSX element representing the new trigger form.
 */

const NewTriggerToWorkTemplate = ({ divisionId, form, activeForm }) => {
  const { isMobile } = useIsMobile();
  const intl = useIntl();
  const organizationId = useSelector(({ organizations }) => organizations.organization.id);
  const [disabled, setDisabled] = useState(false);

  const orgLocationsRef = collection(db, 'organizations', organizationId, 'locations');
  const divLocationsRef = collection(
    db,
    'organizations',
    organizationId,
    'divisions',
    divisionId,
    'locations',
  );
  const divEventTypesRef = collection(
    db,
    'organizations',
    organizationId,
    'divisions',
    divisionId,
    'event_types',
  );
  const { data: orgLocations = [] } = useFirestoreQuery(orgLocationsRef, [organizationId]);
  const { data: divLocations = [] } = useFirestoreQuery(divLocationsRef, [
    organizationId,
    divisionId,
  ]);
  const { data: eventTypes, loading: eventTypesLoading } = useFirestoreQuery(divEventTypesRef, [
    organizationId,
    divisionId,
  ]);

  const locations = useMemo(
    () =>
      [...divLocations, ...orgLocations].map(item => ({
        ...item,
        value: item.id,
        label: item.name,
      })),
    [divLocations, orgLocations],
  );

  const onChangeLocations = useCallback(
    loc => {
      const isModifiable = form.getFieldValue('locationOptional');
      const locationFounded = locations.find(({ id }) => id === loc?.value) || {};

      if (isModifiable && !locationFounded) {
        return form.setFieldsValue({ location: [] });
      }
      return form.setFieldsValue({
        location: locationFounded || {},
      });
    },
    [form, locations],
  );

  const onChangeModifiable = useCallback(
    isModifiable => {
      const hasLocSelected = form.getFieldValue('location');
      if (isModifiable && !hasLocSelected) {
        form.setFieldsValue({ location: [] });
      }
    },
    [form],
  );

  const onRequiredChange = e => {
    if (e) {
      form.setFieldsValue({ locationOptional: e });
    }
    setDisabled(e);
  };

  useEffect(() => {
    form.setFieldsValue({
      eventType: null,
      name: '',
      description: '',
      location: undefined,
      locationOptional: false,
      locationRequired: false,
      cooldown: null,
    });
  }, [form]);

  return (
    <BoxContainer content>
      <Row gutter={16}>
        <Col className="gx-pb-3" span={24}>
          <Title.LabelForm
            required
            className="gx-guarnic-pb-1"
            value={intl.formatMessage({ id: 'work.template.form.template.tasks.event.label' })}
          />
          <Form.Item
            rules={[
              {
                required: activeForm,
                message: intl.formatMessage({
                  id: 'form.required',
                }),
              },
            ]}
            name="eventType"
          >
            <FormSelect
              labelInValue
              disabled={eventTypesLoading}
              showSearch
              options={eventTypes}
              onChange={e => {
                form.setFieldsValue({ eventType: eventTypes.find(({ id }) => id === e.value) });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title.LabelForm
            required
            className="gx-guarnic-pb-1"
            value={intl.formatMessage({
              id: 'work.template.form.template.tasks.triggerName.label',
            })}
          />
          <Form.Item
            rules={[
              {
                required: activeForm,
                message: intl.formatMessage({
                  id: 'form.required',
                }),
              },
            ]}
            name="name"
          >
            <Input className="gx-w-100" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title.LabelForm
            className="gx-guarnic-pb-1"
            value={intl.formatMessage({
              id: 'work.template.form.template.tasks.description.label',
            })}
          />
          <Form.Item name="description">
            <Input className="gx-w-100" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start" gutter={[8, 8]}>
        <Col lg={18} xxl={18} xs={10}>
          <Title.LabelForm
            className="gx-guarnic-pb-1"
            value={intl.formatMessage({
              id: 'work.template.form.template.tasks.location.label',
            })}
          />
          <Form.Item name="location">
            <Select
              className="gx-w-100"
              labelInValue
              optionFilterProp="title"
              optionLabelProp="title"
              showSearch
              onChange={onChangeLocations}
              allowClear
              disabled={disabled}
            >
              {locations.map(({ id, name, type }) => (
                <Select.Option value={id} key={id} title={name}>
                  {isMobile ? (
                    <div>
                      <div>{name}</div>
                      <div>{type}</div>
                    </div>
                  ) : (
                    <Poi name={name} type={type} />
                  )}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={3} xxl={3} xs={7}>
          <Title.LabelForm
            value={<IntlMessages id="work.template.form.modifiable" />}
            className="gx-guarnic-pb-1"
          />
          <Form.Item name="locationOptional" valuePropName="checked">
            <Switch disabled={disabled} onChange={onChangeModifiable} checked={false} />
          </Form.Item>
        </Col>
        <Col lg={3} xxl={3} xs={7}>
          <Title.LabelForm
            value={<IntlMessages id="work.template.form.required" />}
            className="gx-guarnic-pb-1"
          />
          <Form.Item name="locationRequired" valuePropName="checked">
            <Switch onChange={onRequiredChange} checked={false} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Title.LabelForm
            required
            className="gx-guarnic-pb-1"
            value={intl.formatMessage({
              id: 'work.template.form.template.tasks.cooldown.label',
            })}
          />
          <Form.Item
            name="cooldown"
            rules={[
              {
                required: activeForm,
                message: intl.formatMessage({
                  id: 'form.required',
                }),
              },
            ]}
          >
            <DatePicker.TimePicker
              className="gx-m-0 gx-w-100"
              format="HH:mm"
              minuteStep={5}
              showNow={false}
            />
          </Form.Item>
        </Col>
      </Row>
    </BoxContainer>
  );
};

NewTriggerToWorkTemplate.propTypes = {
  divisionId: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  activeForm: PropTypes.bool.isRequired,
};

export default NewTriggerToWorkTemplate;
