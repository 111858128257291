export const ACTIONS = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
};

export const ORGANIZATIONS = `organizations`;
export const DIVISIONS = 'divisions';
export const SCHEDULE_TEMPLATES = 'schedule_templates';
export const SCHEDULES = 'schedules';
export const EXECUTIONS = 'executions';
export const TRIGGERS = 'triggers';
export const SCHEDULE_OPTIONS = 'options';
export const LOCATION_ALERTS = 'location_alerts';
export const DASHBOARDS = 'dashboards';
export const CHARTS = 'charts';
export const EVENT_CHARTS = 'events';
export const DASHBOARD_EVENTS_TABLES = 'tables';

// Divisions
export const getRouteToDivision = orgId => `${ORGANIZATIONS}/${orgId}/${DIVISIONS}`;

// Schedule Templates
export const getRouteToScheduleTemplates = (orgId, divId) =>
  `${ORGANIZATIONS}/${orgId}/${DIVISIONS}/${divId}/${SCHEDULE_TEMPLATES}`;

// Schedule
export const getRouteToSchedule = (orgId, divId) =>
  `${ORGANIZATIONS}/${orgId}/${DIVISIONS}/${divId}/${SCHEDULES}`;

// schedule/options
export const getRouteToScheduleOptions = (orgId, divId, scheduleId) =>
  `${ORGANIZATIONS}/${orgId}/${DIVISIONS}/${divId}/${SCHEDULES}/${scheduleId}/${SCHEDULE_OPTIONS}`;

// organizations/locationAlerts
export const getRouteToLocationAlerts = orgId => `${ORGANIZATIONS}/${orgId}/${LOCATION_ALERTS}`;

/* DASHBAORDS */

// Dashboard/Charts
export const getRouteToEventsCharts = orgId =>
  `${ORGANIZATIONS}/${orgId}/${DASHBOARDS}/${EVENT_CHARTS}/${CHARTS}`;

// Dashboard/Tables
export const getRouteToEventsTables = orgId =>
  `${ORGANIZATIONS}/${orgId}/${DASHBOARDS}/${EVENT_CHARTS}/tables`;

// Dashboards/BOARDS
export const getRouteToNewBoardByOrg = orgId => `${ORGANIZATIONS}/${orgId}/${DASHBOARDS}`;
export const getRouteToNewBoardByDivision = (orgId, divId) =>
  `${ORGANIZATIONS}/${orgId}/${DIVISIONS}/${divId}/${DASHBOARDS}`;

// Dashboards/BOARD_ID/CHARTS
export const getRouteToChartByOrg = (orgId, boardId) =>
  `${ORGANIZATIONS}/${orgId}/${DASHBOARDS}/${boardId}/${CHARTS}`;

export const getRouteToChartByDivision = (orgId, divId, boardId) =>
  `${ORGANIZATIONS}/${orgId}/${DIVISIONS}/${divId}/${DASHBOARDS}/${boardId}/${CHARTS}`;

// Dashboards/BOARD_ID/TABLES
export const getRouteToTableByOrg = (orgId, boardId) =>
  `${ORGANIZATIONS}/${orgId}/${DASHBOARDS}/${boardId}/${DASHBOARD_EVENTS_TABLES}`;

export const getRouteToTableByDivision = (orgId, divId, boardId) =>
  `${ORGANIZATIONS}/${orgId}/${DIVISIONS}/${divId}/${DASHBOARDS}/${boardId}/${DASHBOARD_EVENTS_TABLES}`;
