import React from 'react';
import { ALLOWED_ROLES } from 'packages/utils';

import { PATHS } from './constants';
import ActiveSchedulesDashboard from './screens/ActiveSchedulesDashboard';
import SchedulesDashboard from './screens/SchedulesDashboard';
import EventsDashboard from './screens/EventsDashboard';
import ChartEdit from './screens/EventsDashboard/components/ChartEdit';
import ActiveUserDashboard from './screens/ActiveUserDashboard';
import { SlidePanelProvider } from './components/SlidePanel/SlidePanelContext';

export default [
  {
    component: SchedulesDashboard,
    path: PATHS.SCHEDULES,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.EVENTS_SUMMARY.LIST,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.SCHEDULES}`,
  },
  {
    component: ActiveSchedulesDashboard,
    path: PATHS.ACTIVE_SCHEDULES,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.EXECUTIONS.LIST,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.ACTIVE_SCHEDULES}`,
  },
  {
    component: props => (
      <SlidePanelProvider>
        <EventsDashboard {...props} />
      </SlidePanelProvider>
    ),
    path: PATHS.EVENTS,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.LIST,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.EVENTS}`,
  },
  {
    component: props => (
      <SlidePanelProvider>
        <EventsDashboard {...props} />
      </SlidePanelProvider>
    ),
    path: `${PATHS.EVENTS}/:boardId`,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.LIST,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.EVENTS}`,
  },
  {
    component: ChartEdit,
    path: PATHS.EDIT_CHART,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DASHBOARDS.CHARTS.UPDATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.EVENTS}`,
  },
  {
    component: ActiveUserDashboard,
    path: PATHS.ACTIVE_USER_DASHBOARD,
    allowedRoles: null, // TODO: add roles
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.ACTIVE_USER_DASHBOARD}`,
  },
  {
    component: SchedulesDashboard,
    path: PATHS.TIMELINE_EVENT_DRAWER,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.SCHEDULES}`,
  },
];
