/* eslint-disable no-underscore-dangle */
import React from 'react';
import { COMPONENT_KEY_VALUE_BY_TYPE } from 'packages/utils/constants/eventTypes';
import IntlMessages from 'util/IntlMessages';
import moment from 'moment';
import { TYPE_OF_COMPONENTS } from 'constants/commons';
import { Tags, Toggle } from 'components/Form/components/ComponentTypes';
import { convertToDate } from 'packages/utils/dates';
import SorterColumn from 'components/Table/components/SorterColumn/SorterColumn';
import safeExecute from 'util/safeExecute';
import { get, set } from 'packages/utils/sessionStorage';
import { AUX_FIELDS_EXTRA, AUX_FIELDS_TABLE_FORM } from '../../constants';

export const PAGE_SIZE_BY_DEFAULT = 20;
export const PAGE_SIZE_BY_DEFAULT_MOBILE = 5;

const sorterColumnRef = React.createRef({});
const tableDocumentsRef = React.createRef({});

const SORT_FIELDS_ACCORDING_ELASTIC_RESPONSE = {
  [AUX_FIELDS_EXTRA.userName]: 'user.firstName',
  [AUX_FIELDS_EXTRA.eventDate]: 'createdAtDevice._seconds',
  [AUX_FIELDS_EXTRA.eventType]: 'eventType.name',
  [AUX_FIELDS_EXTRA.division]: 'division.name',
};

const formatDate = val => {
  if (val?._seconds >= 0 && val?._nanoseconds >= 0) {
    return moment(convertToDate(val._seconds, val._nanoseconds)).format('DD-MM-YYYY HH:mm:ss');
  }
  return null;
};

const buildCustomKey = (componentId, label) => `${componentId}_${label?.replaceAll(' ', '_')}`;

const onUpdateSort = async (tableId, onSortFieldsChange) => {
  const metricsBoardStorage = get('metricsTable');
  safeExecute(async () => {
    const sortFields = Object.keys(sorterColumnRef.current)
      .map(key => ({
        name: SORT_FIELDS_ACCORDING_ELASTIC_RESPONSE[key],
        order: sorterColumnRef.current[key]?.order,
      }))
      .filter(({ order }) => !!order);

    const sortFieldToBeSaved = {
      ...metricsBoardStorage,
      [tableId]: {
        sortFields,
      },
    };
    set('metricsTable', sortFieldToBeSaved);

    onSortFieldsChange(prev => ({
      ...prev,
      [tableId]: {
        sortFields,
      },
    }));
  });
};

const onSortTable = async ({ sortFields, tableId, onSortFieldsChange }) => {
  sorterColumnRef.current = {
    ...sorterColumnRef.current,
    ...sortFields,
  };

  await onUpdateSort(tableId, onSortFieldsChange);
};

const auxColProps = {
  width: 'auto',
};

// NOTE: Since the sortFields are saved in the table, we need to build the default sorter, for that we need to convert the name of the fields to the name of the fields in the form
const buildDefaultSorter = (sortFields = []) =>
  sortFields.map(({ name, order }) => {
    const key = Object.keys(SORT_FIELDS_ACCORDING_ELASTIC_RESPONSE).find(
      k => SORT_FIELDS_ACCORDING_ELASTIC_RESPONSE[k] === name,
    );

    return {
      name: key,
      order,
    };
  });

export const buildDynamicColumns = ({
  columns,
  hits,
  table,
  board,
  onSortFieldsChange,
  sortFields,
}) => {
  // NOTE: add table to the tableDocumentsRef in order to update sortFields, we need to save each table in the ref in orderm to get all data to be updated
  tableDocumentsRef.current = {
    [table.id]: table,
    ...tableDocumentsRef.current,
    board,
  };

  return columns
    .reduce((acc, column) => {
      const { componentId, isActive } = column;

      // NOTE: show only active columns
      if (!isActive) return acc;

      // NOTE: add component fields to the table
      const component = hits
        // eslint-disable-next-line no-underscore-dangle
        .flatMap(hit => hit._source?.eventType?.components || [])
        .find(c => c.id === componentId);

      const colDataIndex = buildCustomKey(componentId, component?.label?.name);
      if (component) {
        acc.push({
          title: component.label.name,
          dataIndex: colDataIndex,
          key: component.id,
          render: (_, value) => {
            if (!value) return null;

            if (component.type === TYPE_OF_COMPONENTS.TAG && !!value[colDataIndex]) {
              return <Tags tags={value[colDataIndex]} />;
            }

            if (component.type === TYPE_OF_COMPONENTS.TOGGLE) {
              return <Toggle value={value[colDataIndex]} />;
            }

            return (
              <span style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                {value[colDataIndex]}
              </span>
            );
          },
          className: 'cancelDrag',
          ...auxColProps,
        });
      }

      // NOTE: add aux fields to the table
      const auxField = AUX_FIELDS_TABLE_FORM.find(aux => aux.componentId === componentId);
      if (auxField) {
        acc.push({
          title: (
            <div className="gx-flex-row gx-guarnic-gap-2">
              <IntlMessages id={`dashboards.events.table.auxFields.${auxField.componentId}`} />
              {auxField.componentId === AUX_FIELDS_EXTRA.eventDate && ( // NOTE: we are gonna to add the sorter only to the date field for a while
                <SorterColumn
                  onChange={p => {
                    onSortTable({
                      sortFields: p,
                      tableId: table.id,
                      onSortFieldsChange,
                    });
                  }}
                  column={column.componentId}
                  defaultValues={buildDefaultSorter(sortFields) || []}
                />
              )}
            </div>
          ),
          dataIndex: column.componentId,
          key: column.componentId,
          className: 'cancelDrag',
          ...auxColProps,
        });
      }

      return acc;
    }, [])
    .sort((a, b) => b.index - a.index); // NOTE: sort by index, it is accoring to the order in the form
};

export const buildDataSource = ({ records, division }) => {
  const hits = records?.hits?.hits || [];

  if (hits.length === 0) return [];

  return hits.reduce((acc, hit) => {
    const { _source: source, _id: id } = hit;
    let record = {
      key: id,
    };

    // VALUE OF COMPONENTS
    const components = source.eventType?.components || [];

    components.forEach(element => {
      if (!COMPONENT_KEY_VALUE_BY_TYPE[element.type]) return;

      const key = buildCustomKey(element.id, element?.label?.name);
      let val = element[COMPONENT_KEY_VALUE_BY_TYPE[element.type]];

      // NOTE: this date belong to component date
      if (element.type === TYPE_OF_COMPONENTS.DATE) {
        val = formatDate(val);
      }

      record = {
        ...record,
        [key]: val,
      };
    });

    // USER - DATA
    const user = source?.user || null;
    if (user) {
      record = {
        ...record,
        [AUX_FIELDS_EXTRA.userName]: `${user.firstName} ${user.lastName}`,
      };
    }

    // DATE - DATA (this date belong to event creation)
    const date = source?.createdAtDevice;
    if (date && !!date?._seconds && !!date?._nanoseconds) {
      record = {
        ...record,
        [AUX_FIELDS_EXTRA.eventDate]: formatDate(date),
      };
    }

    // EVENT TYPE - DATA
    const eventType = source.trigger;
    if (eventType) {
      record = {
        ...record,
        [AUX_FIELDS_EXTRA.eventType]: eventType?.name,
      };
    }
    // DIVISION - DATA
    if (division && division?.name) {
      record = {
        ...record,
        [AUX_FIELDS_EXTRA.division]: division.name,
      };
    }
    // pushing the record
    acc.push(record);

    return acc;
  }, []);
};

export const buildTableParams = ({
  formId = '',
  uid = '',
  orgId = '',
  range = {},
  interval = '',
  divisionIds = [],
  triggerIds = [],
  lastPosition = 0,
  pageSize = PAGE_SIZE_BY_DEFAULT,
  sortFields = [],
}) => {
  let dashboardParams = {
    formId,
    uid,
    organizationId: orgId,
    range,
    interval,
    divisionIds,
    lastPosition,
    pageSize,
  };

  if (triggerIds.length > 0) {
    dashboardParams = {
      ...dashboardParams,
      triggerIds,
    };
  }
  if (sortFields.length > 0) {
    dashboardParams = {
      ...dashboardParams,
      sortFields,
    };
  }

  return dashboardParams;
};
