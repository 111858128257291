import safeExecute from 'util/safeExecute';
import {
  updateBoardByDivision,
  updateBoardByOrg,
  updateChartByDivision,
  updateChartByOrg,
  updateTableByDivision,
  updateTableByOrg,
} from 'util/firebase-operations/dashboards/charts/update';
import {
  deleteBoardByDivision,
  deleteBoardByOrg,
  deleteTableByDivision,
  deleteTableByOrg,
} from 'util/firebase-operations/dashboards/charts/delete';
import {
  createBoardByDivision,
  createBoardByOrg,
  createChartByDivision,
  createChartByOrg,
  createTableByDivision,
  createTableByOrg,
} from 'util/firebase-operations/dashboards/charts/create';

import { defaultAggsConfig } from './constants';

/* TABLES HANDLERS */
export const onTableForm = async ({ data, orgId, divId, boardId }) =>
  safeExecute(async () => {
    const body = data;
    if (!body) throw new Error('No data provided');
    const tableId = data?.id;

    if (tableId) {
      if (divId) {
        await updateTableByDivision({ data: body, orgId, divId, boardId, tableId });
      } else {
        await updateTableByOrg({ data: body, orgId, boardId, tableId });
      }
    } else if (divId) {
      await createTableByDivision({ data: body, orgId, divId, boardId });
    } else {
      await createTableByOrg({ data: body, orgId, boardId });
    }
  });

export const onDeleteTable = async ({ id, orgId, divId, boardId }) => {
  safeExecute(async () => {
    if (divId) {
      await deleteTableByDivision({ tableId: id, orgId, divId, boardId });
    } else {
      await deleteTableByOrg({ tableId: id, orgId, boardId });
    }
  });
};

/* CHARTS HANDLERS */

export const onChartForm = ({ data, orgId, divId, boardId }) =>
  safeExecute(async () => {
    const chartId = data?.chart?.id;

    if (chartId) {
      const body = data.chart;
      if (divId) {
        await updateChartByDivision({ data: body, orgId, divId, boardId, chartId });
      } else {
        await updateChartByOrg({ data: body, orgId, boardId, chartId });
      }
    } else {
      const newDocData = {
        layout: { x: 1000, y: 1000, h: 2, w: 5 },
        queryParams: {
          triggerId: data?.triggerId ?? undefined,
          componentId: data?.componentId ?? undefined,
        },
        title: data.title,
        aggregations: defaultAggsConfig,
      };

      if (divId) {
        await createChartByDivision({ data: newDocData, orgId, divId, boardId });
      } else {
        await createChartByOrg({ data: newDocData, orgId, boardId });
      }
    }
  });

/* Boards */
export const deleteBoard = async ({ orgId, divId, boardId }) => {
  safeExecute(async () => {
    if (divId) {
      await deleteBoardByDivision({ orgId, divId, boardId });
    } else {
      await deleteBoardByOrg({ orgId, boardId });
    }
  });
};

export const createBoard = async ({ data, orgId, divId }) =>
  safeExecute(async () => {
    if (divId) {
      return createBoardByDivision({ data, orgId, divId });
    }
    return createBoardByOrg({ data, orgId });
  });

export const updateBoard = async ({ data, orgId, divId, boardId }) => {
  safeExecute(async () => {
    if (divId) {
      await updateBoardByDivision({
        data,
        orgId,
        divId,
        boardId,
      });
    } else {
      await updateBoardByOrg({
        data,
        orgId,
        boardId,
      });
    }
  });
};
