import React, { useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Avatar, Button, Card, Divider, Dropdown, Grid } from 'antd';
import { useIntl } from 'react-intl';
import { auth } from 'firebase/firebase';

import { MoreOutlined, UserOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import { ACCESS_TYPES_INTL, ALLOWED_ROLES } from 'packages/utils';
import ComponentBlocker from 'components/ComponentBlocker';
import styles from './styles.module.less';
import { getRouteToUserNotifications } from '../../constants';

const { useBreakpoint } = Grid;
const User = ({
  access = '',
  disabled = false,
  email = '',
  id = '',
  name = '',
  onChangeState = null,
  onDelete = null,
  permissionsURL = '',
}) => {
  const intl = useIntl();
  const hasMenu = !!(onChangeState || onDelete);
  const screens = useBreakpoint();
  const isMobile = screens.xs;
  const history = useHistory();

  // User cannot change his own permissions
  const notHimself = id !== auth.currentUser.uid;
  const onboardingComplete = !!name.trim(); // NOTE: if has not first and last name, then onboarding is not complete

  const getMenu = useCallback(
    () => (
      <Card bodyStyle={{ padding: '8px' }}>
        <div className={classnames('gx-d-flex gx-flex-column', styles.menuC)}>
          <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.USERS.DELETE}>
            {!!onDelete && (
              <Button key="remove-user" type="text" className="gx-m-0" onClick={() => onDelete(id)}>
                <IntlMessages id="users.list.remove" />
              </Button>
            )}
          </ComponentBlocker>
          <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.USERS.UPDATE}>
            {!!onChangeState && (
              <Button
                key="state-user"
                type="text"
                className="gx-m-0"
                onClick={() => onChangeState(id)}
              >
                {disabled ? (
                  <IntlMessages id="users.list.enable" />
                ) : (
                  <IntlMessages id="users.list.disable" />
                )}
              </Button>
            )}
          </ComponentBlocker>
          {isMobile && onboardingComplete && (
            <>
              <div className={classnames('gx-pl-4 gx-pr-4', disabled && styles.disabled)}>
                <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.USERS.UPDATE}>
                  <Link className="ant-btn-text" to={permissionsURL}>
                    <span className="m-bottom-0">
                      <IntlMessages id="users.list.changePermissions" />
                    </span>
                  </Link>
                </ComponentBlocker>
              </div>
              <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.USERS.CREATE}>
                <Button
                  onClick={() => history.push(getRouteToUserNotifications(id))}
                  key="notification"
                  type="text"
                  className="gx-m-0"
                >
                  <IntlMessages id="users.list.notifications" />
                </Button>
              </ComponentBlocker>
            </>
          )}
        </div>
      </Card>
    ),
    [disabled, history, id, isMobile, onChangeState, onDelete, onboardingComplete, permissionsURL],
  );
  return (
    <Card className={styles.wrapper}>
      <div className={styles.mainContainer}>
        <Avatar
          className={classnames(disabled && styles.disabled)}
          icon={<UserOutlined />}
          size={60}
        />
        <div className={classnames('m-left-6', styles.info, disabled && styles.disabled)}>
          <div className={classnames('gx-flex', styles.infoC)}>
            <div className="gx-mb-1">
              <span className={styles.name}>{name}</span>
              <Divider className={styles.divider} type="vertical" />
              <span className={styles.access}>
                {intl.formatMessage({ id: ACCESS_TYPES_INTL[access].name })}
              </span>
            </div>
            <div className={styles.email}>{email}</div>
          </div>
        </div>
        {!isMobile && notHimself && onboardingComplete && (
          <div className={styles.buttonsPanel}>
            <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.USERS.UPDATE}>
              <Link to={permissionsURL}>
                <span className="m-bottom-0">
                  <IntlMessages id="users.list.changePermissions" />
                </span>
              </Link>
            </ComponentBlocker>
            <ComponentBlocker allowedRoles={ALLOWED_ROLES.ORGANIZATIONS.USERS.CREATE}>
              <Link className="gx-pl-3 gx-pr-3" to={getRouteToUserNotifications(id)}>
                <span className="m-bottom-0">
                  <IntlMessages id="users.list.notifications" />
                </span>
              </Link>
            </ComponentBlocker>
          </div>
        )}
        {notHimself && (
          <div className={styles.buttonsPanel}>
            {hasMenu && (
              <ComponentBlocker
                allowedRoles={[
                  ...ALLOWED_ROLES.ORGANIZATIONS.USERS.DELETE,
                  ...ALLOWED_ROLES.ORGANIZATIONS.USERS.UPDATE,
                ]}
              >
                <Dropdown className="gx-mb-0 gx-ml-2" dropdownRender={getMenu} trigger={['click']}>
                  <Button icon={<MoreOutlined className={styles.moreButton} />} type="text" />
                </Dropdown>
              </ComponentBlocker>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

User.propTypes = {
  access: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  permissionsURL: PropTypes.string.isRequired,

  disabled: PropTypes.bool,
  onChangeState: PropTypes.func,
  onDelete: PropTypes.func,
};

export default React.memo(User);
