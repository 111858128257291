import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import { Badge } from 'antd';
import classNames from 'classnames';

const BatteryStatus = ({ batery = 0, className = '' }) => {
  return (
    <div className={classNames('gx-flex-row', className)}>
      <div className="gx-flex-row gx-align-content-center">
        <span className="material-icons-outlined">phone_android</span>
        <div className="gx-flex-row gx-align-content-center gx-pl-1 gx-pr-1">
          <IntlMessages id="common.batery" /> {batery} %
        </div>
        <div className="gx-flex-col gx-align-content-center gx-pl-1 gx-pr-1">
          <Badge
            className="gx-m-0"
            status={batery >= 50 ? 'success' : batery < 50 && batery > 0 ? 'warning' : 'error'}
          />
        </div>
      </div>
    </div>
  );
};

BatteryStatus.Single = ({ batery = 0, className = '' }) => {
  return (
    <div className={classNames('gx-flex-row', className)}>
      <div className="gx-flex-row gx-align-content-center">
        <div className="gx-flex-row gx-align-content-center gx-pl-1 gx-pr-1">{batery} %</div>
        <div className="gx-flex-col gx-align-content-center gx-pl-1 gx-pr-1">
          <Badge
            className="gx-m-0"
            status={batery >= 50 ? 'success' : batery < 50 && batery > 0 ? 'warning' : 'error'}
          />
        </div>
      </div>
    </div>
  );
};

BatteryStatus.propTypes = {
  batery: PropTypes.number,
  className: PropTypes.string,
};

export default BatteryStatus;
