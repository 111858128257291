import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom';

import { db } from 'firebase/firebase';
import safeExecute from 'util/safeExecute';
import { getRouteToLocationAlerts } from 'util/firebase-operations/constants';
import { collection, doc, increment, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';

const MainAlert = () => {
  const [loading, setLoading] = React.useState();

  const params = useParams();
  const history = useHistory();
  const orgId = params?.orgId;
  const locationId = params?.locationId;

  const onIncrementCounter = async id => {
    let successful = false;
    safeExecute(async () => {
      setLoading(true);

      const collectionRef = collection(db, getRouteToLocationAlerts(orgId));
      const docRef = doc(collectionRef, id);

      try {
        await updateDoc(docRef, {
          quantity: increment(1),
        });
        successful = true;
      } catch (e) {
        if (e.code === 'not-found') {
          await setDoc(docRef, {
            id,
            quantity: 1,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          });
          successful = true;
        }
      }

      if (successful) {
        history.push('/locationAlert', {
          orgId,
          locationId,
        });
      }
    }).finally(() => setLoading(false));
  };

  useEffect(() => {
    //NOTE: we are gonna use this to increment the visit number
    onIncrementCounter(locationId);
  }, []);

  if (loading) return <Spin size="large" />;

  return null;
};

export default MainAlert;
