import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import styles from './styles.module.less';

const SlideElement = ({ data, onChange, elementSelected }) => {
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  return (
    <ul className="gx-p-0">
      {data.length > 0 &&
        data.map(board => (
          <li key={board.id} className={styles.noStyleList}>
            <Button
              onClick={() => onChange(board)}
              className={classNames(
                'gx-text-left gx-m-0 gx-p-0',
                elementSelected.id === board.id ? styles.selectedBoard : styles.unSelectedBoard,
              )}
              block
              type="text"
            >
              <div className={styles.blockElementText}>
                {board.name}
                {board.divId && (
                  <span className={classNames(styles.divisionColor, 'gx-ml-1 gx-fs-11')}>
                    ({userOrgDivisions[board?.divId ?? '']?.name})
                  </span>
                )}
              </div>
            </Button>
          </li>
        ))}
    </ul>
  );
};

SlideElement.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  elementSelected: PropTypes.object.isRequired,
};

export default React.memo(SlideElement);
