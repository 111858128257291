import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ChildrenSchema } from 'packages/utils';

import { clearLocalStorageByOrg } from 'util/clearStorageByOrg';
import MenuOption from '../MenuOption';

import styles from './styles.module.less';

const OptionSchema = PropTypes.shape({
  label: ChildrenSchema.isRequired,
  value: PropTypes.string.isRequired,
});

const MenuSelector = React.memo(({ className, footer, onChange, options, placeholder, value }) => {
  const selected = options.find(opt => opt.value === value);
  const [isOpen, setIsOpen] = useState(false);

  const onToggleOpen = () => setIsOpen(!isOpen);
  const onClose = () => setIsOpen(false);
  const onValueChange = event => {
    onClose();
    if (event.currentTarget.value !== value) {
      onChange(event);
      clearLocalStorageByOrg();
    }
  };

  return (
    <div className={classnames(styles.wrapper, className)}>
      <div className={styles.content}>
        <MenuOption
          as="button"
          className={classnames(styles.selector, isOpen && styles.selectorOpen)}
          onClick={onToggleOpen}
        >
          <span className={classnames(styles.optionContent, !selected && styles.empty)}>
            {selected ? selected.label : placeholder}
          </span>
          <i className="material-symbols-outlined">unfold_more</i>
        </MenuOption>
        <div className={classnames(styles.options, isOpen && styles.optionsOpen)}>
          {options.map(opt => (
            <MenuOption
              as="button"
              className={styles.option}
              key={opt.value}
              onClick={onValueChange}
              selected={opt.value === value}
              value={opt.value}
            >
              <span className={styles.optionContent}>{opt.label}</span>
            </MenuOption>
          ))}
          {footer}
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={classnames(styles.layer, isOpen && styles.layerOpen)} onClick={onClose} />
    </div>
  );
});

MenuSelector.propTypes = {
  options: PropTypes.arrayOf(OptionSchema).isRequired,

  className: PropTypes.string,
  footer: ChildrenSchema,
  onChange: PropTypes.func,
  placeholder: ChildrenSchema,
  value: PropTypes.string,
};

MenuSelector.defaultProps = {
  className: '',
  footer: null,
  onChange: Function.prototype,
  placeholder: '',
  value: '',
};

export default MenuSelector;
