import { memo, useEffect } from 'react';

import { useMap } from 'react-leaflet';

/**
 * RecenterAutomatically component.
 * @param {Object} props - Component props. [latitude, longitude]
 * help to center the map according to the given latitude and longitude.
 */
export const RecenterAutomatically = memo(({ geoCode = [] }) => {
  const map = useMap();
  useEffect(() => {
    if (geoCode.length === 0) return;
    map.setView(geoCode);
  }, [geoCode]);
  return null;
});
