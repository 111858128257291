import { DELIVERY_STATUS } from 'components/MemberList/constants';

/**
 * Retrieves the delivery states for users based on their viewed and delivered statuses.
 *
 * @param {Array} viewed - The parameters object.
 * @param {Array} delivered - An object representing users who have received the item.
 * @param {Object} deliveryState - An object representing the current {viewed and delivered} state.
 * @returns {Object} An object where keys are user IDs and values are delivery statuses. Ej: { '1': 'seen', '2': 'delivered' }
 */
export const getUserDeliveryState = ({ viewed = [], delivered = [], deliveryState = {} }) => {
  const status = {};

  // NOTE: assign the delivery status based on the delivery, it's important the order of the checking since the last one will be the one that will be assigned
  const checkingState = (usersViewed = [], usersDelivered = []) => {
    if (usersDelivered?.length) {
      usersDelivered?.forEach(record => {
        status[record?.user?.id] = DELIVERY_STATUS.DELIVERED;
      });
    }

    if (usersViewed?.length) {
      usersViewed?.forEach(record => {
        status[record?.user?.id] = DELIVERY_STATUS.SEEN;
      });
    }
  };

  if (Object.keys(deliveryState).length) {
    checkingState(deliveryState.viewed, deliveryState.delivered);
    return status;
  }

  checkingState(viewed, delivered);

  return status;
};
