import React from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import PropTypes from 'prop-types';
import styles from './styles.module.less';
import classNames from 'classnames';
import { ALIGN_CLASSES } from './constants';
import { Empty, Spin } from 'antd';
import IntlMessages from 'util/IntlMessages';

/**
 * ResponsiveTable component renders a responsive table with optional loading state and empty state.
 *
 * @param {Object[]} dataSource - The data source for the table.
 * @param {Object[]} columns - The columns configuration for the table.
 * @param {string} [className=''] - Additional class names for the table.
 * @param {boolean} [loading=false] - Loading state for the table.
 * @param {string|null} [type=null] - Type of the table for additional styling.
 *
 * @param {Object} columns[].key - Unique key for the column.
 * @param {string|React.ReactNode} columns[].title - Title of the column.
 * @param {string} columns[].dataIndex - Data index for the column.
 * @param {Function} [columns[].render] - Custom render function for the column.
 * @param {any} [columns[].width] - Width of the column.
 * @param {'left'|'center'|'right'} [columns[].align] - Alignment of the column.
 *
 * @returns {JSX.Element} The rendered responsive table component.
 */

const ResponsiveTable = ({
  dataSource = [],
  columns = [],
  className = '',
  loading = false,
  type = null,
}) => {
  const typeAux = {
    primary: styles.tablePrimary,
  };

  return (
    <Spin spinning={loading}>
      <Table className={classNames(styles.tableContainer, type && typeAux[type], className)}>
        <Thead>
          <Tr>
            {columns.map(column => (
              <Th
                style={{ width: column?.width }}
                className={classNames(ALIGN_CLASSES[column?.align || 'left'])}
                key={column.key}
              >
                {column?.title}
              </Th>
            ))}
          </Tr>
        </Thead>
        {dataSource.length > 0 && !loading ? (
          <Tbody>
            {dataSource.map(data => (
              <Tr key={data.id}>
                {columns.map(column => (
                  <Td className={classNames(ALIGN_CLASSES[column?.contentAlign])} key={column.key}>
                    {column.render ? column.render(data) : data[column.dataIndex]}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        ) : (
          <Tbody>
            <tr>
              <td colSpan={columns.length}>
                <Empty description={<IntlMessages id="general.empty.state" />} />
              </td>
            </tr>
          </Tbody>
        )}
      </Table>
    </Spin>
  );
};

ResponsiveTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      dataIndex: PropTypes.string.isRequired,
      render: PropTypes.func,
      width: PropTypes.any,
      align: PropTypes.oneOf(['left', 'center', 'right']),
      contentAlign: PropTypes.oneOf(['left', 'center', 'right']),
    }),
  ).isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export default React.memo(ResponsiveTable);
