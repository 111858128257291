import {
  ACTIONS,
  getRouteToEventsTables,
  getRouteToNewBoardByDivision,
  getRouteToNewBoardByOrg,
  getRouteToTableByDivision,
  getRouteToTableByOrg,
} from 'util/firebase-operations/constants';
import { createOperation } from 'util/firebase-operations/utils/createOperation';

const deleteEventTable = async ({ orgId, tableId }) =>
  createOperation(getRouteToEventsTables(orgId), tableId, null, ACTIONS.DELETE);

const deleteBoardByOrg = async ({ orgId, boardId }) =>
  createOperation(getRouteToNewBoardByOrg(orgId), boardId, null, ACTIONS.DELETE);

const deleteBoardByDivision = async ({ orgId, divId, boardId }) =>
  createOperation(getRouteToNewBoardByDivision(orgId, divId), boardId, null, ACTIONS.DELETE);

const deleteTableByOrg = async ({ orgId, boardId, tableId }) =>
  createOperation(getRouteToTableByOrg(orgId, boardId), tableId, null, ACTIONS.DELETE);

const deleteTableByDivision = async ({ orgId, divId, boardId, tableId }) =>
  createOperation(getRouteToTableByDivision(orgId, divId, boardId), tableId, null, ACTIONS.DELETE);

export {
  deleteEventTable,
  deleteBoardByOrg,
  deleteBoardByDivision,
  deleteTableByOrg,
  deleteTableByDivision,
};
