import { collection, query, where } from 'firebase/firestore';
import { LOCATION_ALERTS } from 'util/firebase-operations/constants';

import { db } from 'firebase/firebase';
import useFirestoreRealtimeBatched from '../useFirestoreRealtimeBatched/useFirestoreRealtimeBatched';

const useGetLocationAlerts = ({ orgId, locationId }) => {
  const conditions = [];
  if (locationId) {
    conditions.push(where('id', '==', locationId));
  }
  const locationAlertsRef = query(
    collection(db, 'organizations', orgId, LOCATION_ALERTS),
    ...conditions,
  );

  const { data, error, loading } = useFirestoreRealtimeBatched([locationAlertsRef], [orgId]);

  const sortedData = data?.map(alert => ({
    ...alert,
    descriptions: alert.descriptions?.sort((a, b) => b.date.toMillis() - a.date.toMillis()),
  }));

  return {
    data: locationId ? sortedData?.[0] : sortedData,
    error,
    loading,
  };
};

export default useGetLocationAlerts;
