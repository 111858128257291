import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import L, { divIcon } from 'leaflet';
import { Popup } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { Avatar, Button, Space, Spin } from 'antd';

import Map from 'components/Maps/Map';
import Radious from 'components/Maps/Radious';
import { getInitialsName } from 'packages/utils';
import { get, set } from 'packages/utils/storage';
import BoxContainer from 'components/BoxContainer';
import Coordinates from 'components/Maps/Coordinates';
import EventsOnMap from 'components/Maps/EventsOnMap';
import { renderToStaticMarkup } from 'react-dom/server';
import MarkerOnMoving from 'components/Maps/MarkerOnMoving';
import useGetCurrentLocation from 'packages/utils/hooks/useGetCurrentLocation';
import { PauseOutlined, PlayCircleOutlined, RetweetOutlined } from '@ant-design/icons';
import {
  CHARTS,
  getCurrentPosByUser,
  parseGeoData,
  POSITION_HISTORY_STATE,
} from 'packages/reports/constants';

import CurrentLocation from 'components/Maps/CurrentLocation';
import styles from './styles.module.less';
import { UserDetail } from './UserDetails';

import 'leaflet-gesture-handling';

import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

const DUMMY = 'DUMMY';
const UserHistoryMap = ({
  users = [],
  loading = false,
  locationData,
  onLocationData,
  events,
  relationByChart,
}) => {
  const { name: organizationName = '', address: { latitude, longitude } = {} } = useSelector(
    ({ organizations }) => organizations?.organization?.data || {},
  );
  const hasOrgLocation = !!latitude && !!longitude;
  const { currentLocation, loading: gpsLoading } = useGetCurrentLocation(
    !hasOrgLocation ? [DUMMY] : [],
  );

  const mapRef = useRef();
  const mapStateRef = useRef({ zoom: get(POSITION_HISTORY_STATE)?.zoom || 16, orgLocation: [] });
  const coordinateCircleRef = useRef([]);
  const eventMarkersRef = useRef({
    clusterRef: null,
    markersByEvent: [],
  });
  const currentMarkerRef = useRef({
    index: 0,
  });
  /** Manage 3 states
   * NULL reset
   * 0 START
   * 1 PAUSE
   */
  const [movingState, setMovingState] = useState(null);
  const [speed, setSpeed] = useState(1000);
  const userActives = users
    ?.filter(usr => usr?.status === true && !!usr?.geoData) // NOTE: with this we keep in asynchronous form with userList
    .map(usr => {
      const geoDataSorted = usr?.geoData?.sort((a, b) => a.timestamp - b.timestamp);

      return {
        ...usr,
        geoData: geoDataSorted,
      };
    });
  const iconMarkup = usr =>
    renderToStaticMarkup(
      <Avatar
        size={24}
        style={{ backgroundColor: usr.color }}
        icon={<span>{getInitialsName(`${usr?.firstName} ${usr?.lastName}`)}</span>}
      />,
    );
  const customIcon = usr =>
    // eslint-disable-next-line new-cap
    new divIcon({
      html: iconMarkup(usr),
      className: styles.userSelected,
    });

  const onMovingState = c => {
    setMovingState(c);
  };

  const onChangeMap = useCallback(
    (latLng, distance, zoom) => {
      const newLocation = {
        radious: distance,
        latLng,
      };

      let auxLastState = null;
      onLocationData(newLocation);
      if (zoom) {
        auxLastState = { zoom };
        mapStateRef.current.zoom = zoom;
      }

      if (latLng) {
        auxLastState = { ...auxLastState, latLng };
      }
      if (distance) {
        auxLastState = { ...auxLastState, distance };
      }
      if (auxLastState) {
        set(POSITION_HISTORY_STATE, {
          ...get(POSITION_HISTORY_STATE),
          ...auxLastState,
        });
      }
    },
    [onLocationData],
  );

  const onSpeed = () => {
    setSpeed(prev => (prev === 1000 ? 500 : 1000));
  };

  // NOTE: avoid that with one finger doing scrolling
  useEffect(() => {
    L.Map.addInitHook('addHandler', 'gestureHandling', L.GestureHandling);
  }, []);

  useEffect(() => {
    const location = hasOrgLocation ? [latitude, longitude] : currentLocation;
    if (location?.length > 0) {
      if (mapRef.current) {
        mapRef.current?.setView(location);
        mapStateRef.current.orgLocation = location;

        onLocationData({
          ...locationData,
          latLng: location,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, gpsLoading, mapRef.current]);

  // NOTE: Here we are goign to go according chart actions
  useEffect(() => {
    const { location, eventId } = relationByChart?.data;

    if (!location || !eventId) return;

    // MANAGE SPEED BY USER CHART
    if (relationByChart.chart === CHARTS.speed_by_user && relationByChart?.data !== null) {
      if (coordinateCircleRef.current) {
        const circleMarker = coordinateCircleRef.current[eventId] || null;

        if (circleMarker) circleMarker.openPopup();
      }
    }

    // MANAGE EVENT EVOLUTION CHART
    if (relationByChart.chart === CHARTS.event_evolution) {
      const eventMarker = eventMarkersRef.current.markersByEvent[eventId] || null;
      if (eventMarker && mapRef.current) {
        mapRef.current.whenReady(() => {
          eventMarkersRef.current.clusterRef.zoomToShowLayer(eventMarker, () => {
            eventMarker?.openPopup();
          });
        });
      }
    }
  }, [relationByChart]);

  const fetchingComponents = loading || gpsLoading;

  return (
    <BoxContainer className={styles.userHistoryMapContainer}>
      <Space className="gx-mb-2">
        <Button
          className="gx-mb-0"
          onClick={() => onMovingState(movingState === 0 ? 1 : 0)}
          icon={movingState === 0 ? <PauseOutlined /> : <PlayCircleOutlined />}
        />
        <Button
          className="gx-mb-0"
          onClick={() => onMovingState(null)}
          icon={<RetweetOutlined />}
        />
        <Button
          onClick={onSpeed}
          className="gx-mb-0"
          icon={<div>x {speed === 1000 ? '2' : '1'}</div>}
        />
      </Space>
      <Map
        gestureHandling
        className={styles.customMap}
        center={locationData.latLng}
        zoom={mapStateRef.current?.zoom}
        mapRef={mapRef}
        loading={fetchingComponents}
      >
        {userActives.length > 0 &&
          userActives.map((usr, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i}>
              <Coordinates
                highlightPos={relationByChart?.data?.id}
                positions={usr.geoData}
                color={usr?.color}
                circleRef={coordinateCircleRef}
              />
              <MarkerOnMoving
                positions={parseGeoData(usr.geoData)}
                icon={customIcon(usr)}
                movingState={movingState}
                markerRef={currentMarkerRef}
                speed={speed}
                onMovingState={onMovingState}
              >
                <Popup className={styles.userDetailPopup}>
                  <UserDetail user={getCurrentPosByUser(usr, currentMarkerRef.current.index)} />
                </Popup>
              </MarkerOnMoving>
            </div>
          ))}
        <Radious onChangeMap={onChangeMap} />
        {hasOrgLocation && (
          <CurrentLocation
            position={{
              lat: mapStateRef.current?.orgLocation[0],
              lng: mapStateRef.current?.orgLocation[1],
            }}
            icon="location_city"
            popupText={organizationName}
          />
        )}
        <EventsOnMap eventMarkersRef={eventMarkersRef} data={events} /> (
        <Spin>
          <div style={{ widht: '600px', height: '400px' }} />
        </Spin>
        )
      </Map>
    </BoxContainer>
  );
};

UserHistoryMap.defaultProps = {
  users: [],
  events: [],
};

UserHistoryMap.propTypes = {
  users: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  onLocationData: PropTypes.func.isRequired,
  locationData: PropTypes.shape({
    radious: PropTypes.number,
    latLng: PropTypes.array,
  }).isRequired,
  events: PropTypes.array,
  relationByChart: PropTypes.shape({
    chart: PropTypes.string,
    data: PropTypes.any,
    cx: PropTypes.number,
  }).isRequired,
};

export default React.memo(UserHistoryMap);
