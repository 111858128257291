import { useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useRemoteConfig from '../useRemoteConfig';

// This hook is used to check if the user is allowed to see the view
export const useOrganizationsAllowedRC = ({ remoteConfigName, redirect = true }) => {
  const orgAllowedRef = useRef(null);
  const history = useHistory();
  const orgId = useSelector(({ organizations }) => organizations.organization.id);

  const { value: organizationsAllowed, isLoaded } = useRemoteConfig(remoteConfigName);

  useEffect(() => {
    if (isLoaded) {
      // If there are no organizations allowed, then we allow all
      if (Object.keys(organizationsAllowed).length === 0) return;

      const orgAllowed = organizationsAllowed[orgId] || null;
      orgAllowedRef.current = orgAllowed;
      if (!orgAllowed && redirect) {
        history.push('/');
      }
    }
  }, [history, isLoaded, organizationsAllowed, orgId, redirect]);

  return {
    organizationsAllowed: orgAllowedRef.current,
    isLoaded,
  };
};
