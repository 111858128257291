import React from 'react';
import { useLocation } from 'react-router-dom';

import { db } from 'firebase/firebase';
import safeExecute from 'util/safeExecute';
import { getRouteToLocationAlerts } from 'util/firebase-operations/constants';
import { arrayUnion, collection, doc, Timestamp, updateDoc } from 'firebase/firestore';

import styles from './styles.module.less';
import { FORM_STATUS } from '../../constants';
import LocationAlertForm from '../../components/LocationAlertForm';
import OperondaLogo from 'components/OperondaLogo';
import LocationAlertSuccessful from '../../components/LocationAlertSuccessful';

function LocationAlert() {
  const [loading, setLoading] = React.useState();
  const [locationAlertDescription, setLocationAlertDescription] = React.useState('');
  const [formStatus, setFormStatus] = React.useState(null);

  const {
    state: { orgId = '', locationId = '' },
  } = useLocation() || {};

  const onSendForm = async () => {
    safeExecute(async () => {
      if (!orgId || !locationId) throw new Error('Try again');

      setLoading(true);

      const collectionRef = collection(db, getRouteToLocationAlerts(orgId));
      const docRef = doc(collectionRef, locationId);

      updateDoc(docRef, {
        descriptions: arrayUnion({
          description: locationAlertDescription,
          date: Timestamp.now(),
        }),
      }).then(() => {
        setFormStatus(FORM_STATUS.SUBMITTED);
      });
    }).finally(() => setLoading(false));
  };

  return (
    <div className={styles.locationAlertWrapper}>
      <div className="gx-flex-column gx-justify-content-between">
        <div className="gx-flex-row gx-justify-content-center">
          <OperondaLogo />
        </div>
        {FORM_STATUS.SUBMITTED !== formStatus && (
          <LocationAlertForm
            loading={loading}
            onChange={setLocationAlertDescription}
            onSubmit={onSendForm}
          />
        )}
        {FORM_STATUS.SUBMITTED === formStatus && <LocationAlertSuccessful />}
      </div>
    </div>
  );
}

export default LocationAlert;
