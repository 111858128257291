import { onSnapshot, doc } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import { sendErrorToSentry } from 'util/sentry/utils';

const subscribeToUserMetrics = (orgId, userId, callback) => {
  const docRef = doc(db, 'organizations', orgId, 'users', userId, 'metrics', 'UNIQUE_ID');

  return onSnapshot(
    docRef,
    docSnap => {
      if (docSnap?.data()) {
        callback(docSnap.data());
      } else {
        callback(null);
      }
    },
    error => {
      sendErrorToSentry(error, { userId, functionName: 'subscribeToUserMetrics' });
    },
  );
};

export default subscribeToUserMetrics;
