import React, { useEffect } from 'react';
import URLSearchParams from 'url-search-params';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import esES from 'antd/es/locale/es_ES';
import enUS from 'antd/es/locale/en_US';

import 'packages/styles';
import { AcceptInvitationScreen } from 'packages/users';
import AppLocale from 'lngProvider';
import {
  authTokenListenerRegister,
  onLayoutTypeChange,
  onNavStyleChange,
  setInitUrl,
  setThemeType,
} from 'appRedux/actions';
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from 'constants/ThemeSetting';

import Onboarding from './Onboarding';
import Auth from './Auth';
import Error404 from './Error404';
import Error403 from './Error403';
import MainApp from './MainApp';
import Notifications from './Subscriptions/screens/Notification';
import LocationAlert from './LocationAlert/screens/Alert';
import MainAlert from './LocationAlert/screens/MainAlert';
const INVITATION_REGEXP = /^\/invitation\/([a-z0-9=]{5,})\/?$/i;

const anonymousPaths = [
  /^\/forgot_password\/?$/,
  /^\/signin\/?$/,
  /^\/signup\/?$/,
  INVITATION_REGEXP,
];

const App = () => {
  const dispatch = useDispatch();
  const { locale, navStyle, layoutType } = useSelector(({ settings }) => settings);
  const { authUser, initURL } = useSelector(({ auth }) => auth);
  const [loaded, onboardingCompleted] = useSelector(({ user }) => [
    user.profile.loaded,
    user.profile.data?.onboardingCompleted,
  ]);

  const location = useLocation();
  const history = useHistory();
  const { pathname, search } = location;
  const shouldFetchUser = !!authUser && !loaded;

  useEffect(() => {
    if (shouldFetchUser) {
      dispatch(authTokenListenerRegister());
    }
  }, []);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(pathname));
    }
    const params = new URLSearchParams(search);

    if (params.has('theme')) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has('nav-style')) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has('layout-type')) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [dispatch, pathname, search, initURL, layoutType, navStyle]);

  const setLayoutType = type => {
    if (type === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (type === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (type === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = style => {
    if (
      style === NAV_STYLE_DEFAULT_HORIZONTAL ||
      style === NAV_STYLE_DARK_HORIZONTAL ||
      style === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      style === NAV_STYLE_ABOVE_HEADER ||
      style === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  useEffect(() => {
    const isAnonymousPath = anonymousPaths.some(ap => pathname.match(ap));
    const isInvitationPath = pathname.match(INVITATION_REGEXP);
    const isOnboardingPath = pathname.match(/^\/onboarding(|\/wizard)\/?$/);
    const isSubscriptionPath = pathname.match(/^\/subscriptions(\/.*)?$/);
    const isLocationAlertPath = pathname.match(/^\/locationAlert(\/.*)?$/);

    //NOTE: Here we are going to exclude the soem paths since they are public paths
    if (isSubscriptionPath || isLocationAlertPath) return;

    if (!shouldFetchUser) {
      // We don't have a clue about the user

      if (loaded) {
        if (!onboardingCompleted) {
          // We've loaded the user but is not onboarded or it was invited
          if (!isOnboardingPath && !isInvitationPath) {
            history.push('/onboarding');
          }
        } else if (!isInvitationPath && (isAnonymousPath || isOnboardingPath)) {
          // We've loaded the user but this is a public path
          history.push('/');
        }
      } else if (!isAnonymousPath) {
        // This is a restricted path
        history.push('/signin');
      }
    }
  }, [dispatch, history, loaded, onboardingCompleted, pathname, shouldFetchUser]);

  const currentAppLocale = AppLocale[locale.locale];
  const antdLocale = { locale: currentAppLocale.locale === 'es' ? esES : enUS };

  return (
    <ConfigProvider {...antdLocale}>
      <NotificationContainer />

      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <Switch>
          <Route component={AcceptInvitationScreen} exact path="/invitation/:token" />
          <Route component={Error403} exact path="/error403" />
          <Route component={Error404} exact path="/error404" />
          <Route component={Notifications} exact path="/subscriptions/notifications/:type" />
          <Route component={Notifications} exact path="/subscriptions/notifications/:type/:token" />
          <Route component={MainAlert} exact path="/locationAlert/:orgId/:locationId" />
          <Route component={LocationAlert} exact path="/locationAlert" />

          {!loaded && <Route component={Auth} path="/" />}
          {!shouldFetchUser &&
            (onboardingCompleted ? (
              <Route path="/:orgId?/-" component={MainApp} />
            ) : (
              <Route component={Onboarding} path="/onboarding" />
            ))}
          <Redirect exact from="/" to="/-" />
          <Redirect to="/error404" />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default App;
