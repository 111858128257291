import { remove } from 'packages/utils/storage';

export const clearLocalStorageByOrg = () => {
  remove('metrics_filter');
  remove('position_history_state');
  remove('gpsLastPosition');
  remove('workOrdersFilters');
  remove('schedulesDashboardFilters');
  remove('timelineFilters');
  remove('metricsBoard');
};
