export const isDev = process.env.NODE_ENV !== 'production';

export const SCHEDULE_TYPES = {
  schedule: 'schedule',
  task: 'task',
};

// EXECUTIONS
// WORK ORDER STATUS
export const EXECUTION_STATUS = {
  active: 'active',
  delayed: 'delayed',
  inactive: 'inactive',
  incomplete: 'incomplete',
  completed: 'completed',
  expired: 'expired',
};

export const SCHEDULE_STATUS = {
  active: 'active',
  completed: 'completed',
  expired: 'expired',
};

// Components of forms
export const TYPE_OF_COMPONENTS = {
  FILE: 'file',
  NUMBER: 'number',
  PHOTO: 'photo',
  TAG: 'tag',
  TEXT: 'text',
  TOGGLE: 'toggle',
  VIDEO: 'video',
  SELECT: 'select',
  DATE: 'date',
};
